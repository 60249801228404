import cn from 'classnames'
import * as React from 'react'

import { Button } from 'components/Button'
import { Loader } from 'components/Loader'
import MiniScoreboard from 'components/MiniScoreboard'
import QuickFirePlayer from 'components/QuickFirePlayer'
import QuickFireSongSelector from 'components/QuickFireSongSelector'
import { IGame } from 'interfaces/IGame'
import { IRound } from 'interfaces/IRound'
import {
    child,
    DatabaseReference,
    DataSnapshot,
    getDatabase,
    off,
    onValue,
    ref,
    serverTimestamp,
    set,
    update,
} from 'firebase/database'
import { IWithRouterProps, withRouter } from 'hoc/withRouter'

import css from './QuickFire.module.scss'

const QUICKFIRE_STATES = {
    LOADING: 'LOADING',
    PICK_SONG: 'PICK_SONG',
    READY: 'READY',
}

interface IQuickFireState {
    game?: IGame
    round?: IRound
}

class QuickFire extends React.Component<IWithRouterProps, IQuickFireState> {
    gameRef?: DatabaseReference
    roundRef?: DatabaseReference

    state: IQuickFireState = {}

    componentDidMount() {
        const { props } = this

        this.gameRef = ref(getDatabase(), `/games/${props.router.params.id}`)
        onValue(this.gameRef, this._handleGameValue)
    }

    _handleGameValue = (snapshot: DataSnapshot) => {
        if (!snapshot) {
            return console.error('Snapshot is empty', snapshot)
        }

        this.setState(
            () => ({ game: snapshot.val() }),
            () => {
                const { roundRef, state } = this

                if (state.game && state.game.round) {
                    if (roundRef) {
                        off(roundRef)
                    }

                    this.roundRef = child(
                        ref(getDatabase(), 'rounds'),
                        state.game.round,
                    )

                    onValue(this.roundRef, this._handleRoundValue)
                }
            },
        )
    }

    _handleRoundValue = (snapshot: DataSnapshot) => {
        if (!snapshot) {
            return console.error('Snapshot is empty', snapshot)
        }

        this.setState(() => ({ round: snapshot.val() }))
    }

    _state = () => {
        const { state } = this

        if (!state.game || !state.round) return QUICKFIRE_STATES.LOADING
        if (!state.round.questions || !state.round.questions.length) {
            return QUICKFIRE_STATES.PICK_SONG
        }

        return QUICKFIRE_STATES.READY
    }

    get question() {
        const { state } = this

        if (!state.round || !state.round.questions || !state.round.question) {
            return null
        }

        return state.round.questions[state.round.question]
    }

    get song() {
        const { question } = this

        if (!question || !question.alternatives) {
            return null
        }

        return question.alternatives[question.correctAlternative]
    }

    get players() {
        const { state } = this

        if (!state.game || !state.game.players) {
            return 0
        }

        return Object.keys(state.game.players).length
    }

    get finished() {
        const { state } = this

        if (!state.round || !state.round.finished) {
            return false
        }

        return state.round.finished
    }

    handlePlay = () => {
        if (this.roundRef) {
            const updates = {
                started: serverTimestamp(),
                'questions/0/started': serverTimestamp(),
            }

            update(ref(getDatabase()), updates).then(() => {
                window.setTimeout(() => {
                    if (this.roundRef) {
                        update(this.roundRef, {
                            finished: serverTimestamp(),
                        })
                    }
                }, 20000)
            })
        }
    }

    handleNextRound = () => {
        const { state } = this

        if (!this.gameRef || !state.game || !state.game.rounds) {
            return
        }

        const currentRound = state.game.round
        const rounds = Object.keys(state.game.rounds)
        const round = rounds.findIndex((r) => r === currentRound)

        set(child(this.gameRef, 'round'), rounds[round + 1])
    }

    render() {
        const { state, _state, question, song, players, finished } = this

        const S = _state()

        if (!state.game || S === QUICKFIRE_STATES.LOADING) {
            return <Loader />
        }

        return (
            <div className={cn(css.quickfire)}>
                <div className={css.code}>
                    <code>{state.game.code}</code>
                </div>
                <strong
                    className={cn(css.players, { [css.empty]: players === 0 })}
                >
                    {players} <span>players</span>
                </strong>
                {S === QUICKFIRE_STATES.PICK_SONG && (
                    <QuickFireSongSelector game={state.game} />
                )}
                {S === QUICKFIRE_STATES.READY && (
                    <div>
                        {!finished && question && song && (
                            <QuickFirePlayer
                                cover={question.imageUrl}
                                name={song.name}
                                artists={song.artists}
                                previewUrl={question.previewUrl}
                                onPlay={this.handlePlay}
                            />
                        )}
                        {finished && (
                            <div>
                                <MiniScoreboard game={state.game} />
                                <Button center onClick={this.handleNextRound}>
                                    Next round
                                </Button>
                            </div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default withRouter(QuickFire)
