import cn from 'classnames'
import * as React from 'react'

import { Button } from 'components/Button'
import { ErrorBox } from 'components/ErrorBox'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { joinGameByCode } from 'utils/firebase'
import { IWithRouterProps, withRouter } from 'hoc/withRouter'

import css from './Join.module.scss'

interface IJoinProps {
    className?: string
    onCancel: () => any
}

export const Join = withRouter(
    class Join extends React.Component<IJoinProps & IWithRouterProps> {
        join?: HTMLDivElement | null
        state = {
            error: null,
            code: '',
        }

        componentDidMount() {
            if (this.join) {
                const input = this.join.querySelector('input')

                if (input) {
                    input.focus()
                }
            }
        }

        joinGame = async (code: string) => {
            const id = await joinGameByCode(code)

            if (id) {
                this.props.router.navigate(`/play/${id}`)
            } else {
                this.setState({ error: 'Invalid game code' })
            }
        }

        _handleSubmit = (
            e: React.FormEvent | React.MouseEvent<HTMLButtonElement>,
        ) => {
            e.preventDefault()
            this.joinGame(this.state.code)
        }

        _onChange = (e: React.ChangeEvent<HTMLInputElement>) =>
            this.setState({ code: e.currentTarget.value.toUpperCase() })

        render() {
            const { props } = this
            const { code, error } = this.state

            return (
                <div
                    className={cn(css.join, props.className)}
                    ref={(node) => (this.join = node)}
                >
                    <button
                        type="button"
                        className="back"
                        onClick={props.onCancel}
                    >
                        Back to lobby
                    </button>
                    <Form onSubmit={this._handleSubmit}>
                        <ErrorBox error={error} />
                        <Input
                            name="code"
                            className={css.code}
                            onChange={this._onChange}
                            placeholder="Code"
                            type="text"
                            value={code}
                            maxLength={5}
                        />
                        <p>
                            <Button
                                center={true}
                                disabled={code.length < 5}
                                onClick={this._handleSubmit}
                            >
                                Join
                            </Button>
                        </p>
                    </Form>
                </div>
            )
        }
    },
)
