import cn from 'classnames'
import * as React from 'react'

import css from './Button.module.scss'

interface IButtonProps {
    center?: boolean
    disabled?: boolean
    color?: 'red' | 'green' | 'purple'
    className?: string
    onClick?: (e: React.MouseEvent<HTMLButtonElement>) => any
    size?: 'small'
    type?: React.ButtonHTMLAttributes<HTMLButtonElement>['type']
}

export const Button: React.SFC<IButtonProps> = ({
    center = false,
    disabled = false,
    color,
    className,
    children,
    onClick,
    size,
    type,
}) => (
    <button
        className={cn(className, css.button, {
            [css.center]: center,
            [css.red]: color === 'red',
            [css.green]: color === 'green',
            [css.small]: size === 'small',
            [css.purple]: color === 'purple',
        })}
        disabled={disabled}
        onClick={onClick}
        type={type}
    >
        {children}
    </button>
)
