import qs from 'query-string'

import { ISpotifyPlaylist } from 'interfaces/ISpotifyPlaylist'

const API = '//yamboo-api.azurewebsites.net/api/'

function get(endpoint: string, parameters?: {}) {
    if (parameters) {
        return fetch(API + endpoint + '?' + qs.stringify(parameters)).then(
            (res) => res.json(),
        )
    } else {
        return fetch(API + endpoint).then((res) => res.json())
    }
}

function post(endpoint: string, body: {}) {
    return fetch(API + endpoint, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    }).then((res) => res.json())
}

// TRACKS
export function getQuestions(tracks: string[]) {
    return post('tracks/questions', tracks)
}

// PLAYLISTS
export function getUserPlaylists(
    username: string,
): Promise<ISpotifyPlaylist[]> {
    return get(`playlists/${username}`)
}

export function getTracksForPlaylist(
    { uid, pid }: { uid: string; pid: string },
    questions: number,
) {
    return post(`playlists/questions`, {
        uid,
        pid,
        questions,
    })
}

export function getPlaylistsForGenre(
    genre: string,
): Promise<{ items: unknown[] }> {
    return get(`playlists/genre`, { categoryId: genre })
}

export interface IApiGenreResult {
    id: string
    name: string
    rating: number
}

export type ApiGenresResult = IApiGenreResult[]

// GENRES
export function getGenres(): Promise<string[]> {
    return get(`genres`)
}

export function getGenresRated(): Promise<ApiGenresResult> {
    return get(`genres/rated`)
}

export function getCategories(): Promise<unknown[]> {
    return get(`genres/categories`)
}

export function getTracksForGenres(
    { genre, level }: { genre: string; level: number },
    questions: number,
) {
    const genres = Array.isArray(genre) ? genre : [genre]

    return post(`genres/questions`, {
        genres,
        level,
        questions,
    })
}

// CREATE GAME

export function createGameFromPlaylist(
    owner: string,
    playlist: string,
    questions = 5,
) {
    return post(`game/create/playlist`, {
        uid: owner,
        pid: playlist,
        questions,
    })
}

// Search
export function search(type: 'track', q: string): void | Promise<any> {
    return get(`search/tracks`, {
        q,
    })
}
