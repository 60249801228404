import { getQuestions, search } from 'api'
import * as React from 'react'
import { ISpotifySearchResult, ISpotifySearchResults } from 'spotify'
import { addQuickFireQuestions } from 'utils/firebase'

import { IGame } from 'interfaces/IGame'
import { IQuestion } from 'interfaces/IQuestion'

import css from './QuickFireSongSelector.module.scss'

interface IQuickFireSongSelectorProps {
    game: IGame
}

interface IQuickFireSongSelectorState {
    results: ISpotifySearchResult[]
    searching: boolean
}

export default class QuickFireSongSelector extends React.Component<
    IQuickFireSongSelectorProps,
    IQuickFireSongSelectorState
> {
    input?: HTMLInputElement | null

    state = {
        results: [] as ISpotifySearchResult[],
        searching: false,
    }

    handleSearchResult = (res: Response) => {
        res.json()
            .then((json: ISpotifySearchResults) => {
                this.setState({
                    results: json.tracks.items,
                })
            })
            .catch(() => {
                window.sessionStorage.removeItem('spotify_token')
                window.location.reload()
            })
    }

    search = (e: React.FormEvent) => {
        e.preventDefault()

        this.setState({ searching: true })

        if (!this.input || !this.input.value) return

        const s = search('track', this.input.value)

        if (s) {
            s.then((results) => this.setState({ results, searching: false }))
        }
    }

    selectTrack = (id: string) =>
        getQuestions([id]).then((json: { questions: IQuestion[] }) => {
            const { props } = this

            if (props.game.round) {
                addQuickFireQuestions(props.game.round, json.questions)
            }
        })

    render() {
        const { state } = this

        return (
            <div className={css.songselector}>
                <form onSubmit={this.search} className={css.search}>
                    <input type="text" ref={(node) => (this.input = node)} />
                    <button disabled={state.searching} type="submit">
                        {state.searching ? '🕑' : '🔍'}
                    </button>
                </form>
                {state.results && (
                    <ul className={css.results}>
                        {state.results.map((result) => (
                            <li key={result.id} className={css.result}>
                                <button
                                    className={css.button}
                                    disabled={!result.previewUrl}
                                    type="button"
                                    onClick={() => this.selectTrack(result.id)}
                                    style={{
                                        textDecoration: !result.previewUrl
                                            ? 'line-through'
                                            : '',
                                    }}
                                >
                                    <div className={css.wrapper}>
                                        <img
                                            className={css.cover}
                                            src={result.imageUrl}
                                            alt=""
                                        />
                                        <div className={css.info}>
                                            <p className={css.name}>
                                                {result.name}
                                            </p>
                                            <p className={css.artists}>
                                                {result.artists
                                                    .map((a) => a.name)
                                                    .join(', ')}
                                            </p>
                                        </div>
                                    </div>
                                </button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    }
}
