import cn from 'classnames'
import * as React from 'react'

import User from 'components/User'

import css from './Stats.module.scss'

const Stats: React.SFC<{
    users?: { [key: string]: boolean }
    className?: string
}> = ({ className, users }) => (
    <ul className={cn(className, css.list)}>
        {users &&
            Object.keys(users).map((q) => (
                <li className={css.user} key={q}>
                    <User id={q} size="small" />
                </li>
            ))}
    </ul>
)

export default Stats
