import cn from 'classnames'
import * as React from 'react'

import css from './Select.module.scss'

export class Select extends React.Component<{
    name?: string
    value?: string
    disabled?: boolean
    onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
    placeholder?: string
}> {
    select: HTMLSelectElement | null = null

    _handleOnClick = () => {
        const element = this.select
        let event
        event = document.createEvent('MouseEvents')
        event.initMouseEvent(
            'mousedown',
            true,
            true,
            window,
            0,
            0,
            0,
            0,
            0,
            false,
            false,
            false,
            false,
            0,
            null,
        )
        element && element.dispatchEvent(event)
    }

    _handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
        this.props.onChange && this.props.onChange(e)

    render() {
        const { children, value, placeholder, ...props } = this.props

        const className = {
            input: cn(css.input, {
                [css.placeholder]: !value,
                [css.disabled]: this.props.disabled,
            }),
        }

        return (
            <div>
                {placeholder && (
                    <label className={cn({ [css.hide]: !value })}>
                        {placeholder}
                    </label>
                )}
                <div className={css.select}>
                    <button
                        className={className.input}
                        onClick={this._handleOnClick}
                        type="button"
                    >
                        {value || placeholder}
                    </button>
                    <select
                        {...props}
                        className={css.element}
                        onChange={this._handleOnChange}
                        ref={(node) => (this.select = node)}
                        value={value}
                    >
                        {children}
                    </select>
                </div>
            </div>
        )
    }
}

export default Select
