import cn from 'classnames'
import * as React from 'react'

import { Avatar } from 'components/Avatar'
import { IUser } from 'interfaces/IUser'
import { user as userCache } from 'utils/cache'
import { get, getDatabase, ref } from 'firebase/database'
import { getApps } from 'firebase/app'

import css from './User.module.scss'

interface IUserProps {
    id?: string
    showName?: boolean
    showAvatar?: boolean
    points?: number
    size?: 'small'
    dimmed?: boolean
    user?: IUser
}

interface IUserState {
    user?: IUserProps['user']
}

export class User extends React.Component<IUserProps, IUserState> {
    static defaultProps = {
        showAvatar: true,
        showName: true,
        dimmed: false,
    }

    state: IUserState = {}

    componentDidMount() {
        if (!this.props.user && this.props.id) {
            this._getUser()
        }
    }

    _getUser() {
        if (this.props.id) {
            const user = userCache.get(this.props.id)
            if (user) {
                return this.setState({ user })
            }
        }

        if (getApps().length) {
            get(ref(getDatabase(), `users/${this.props.id}`)).then((data) => {
                if (this.props.id) {
                    userCache.set(this.props.id, data.val())
                }

                this.setState({
                    user: data.val(),
                })
            })
        } else {
            this.setState({ user: { name: this.props.id } })
        }
    }

    get name() {
        const { state, props } = this

        if (state.user && state.user.name) return state.user.name
        if (props.user && props.user.name) return props.user.name
        return props.id || null
    }

    get avatar() {
        const { state, props } = this

        if (state.user && state.user.avatar) return state.user.avatar
        if (props.user && props.user.avatar) return props.user.avatar
        return null
    }

    render() {
        const { name, avatar } = this
        const { showAvatar, showName, size, dimmed, points } = this.props

        return (
            <div
                className={cn(css.user, {
                    [css.noAvatar]: !showAvatar,
                    [css.small]: size === 'small',
                    [css.dimmed]: dimmed,
                })}
            >
                {showAvatar && avatar && (
                    <Avatar avatar={avatar} className={css.avatar} />
                )}
                {showName && name && <p className={css.name}>{name}</p>}
                {typeof points !== 'undefined' && Number.isInteger(points) && (
                    <p className={css.points}>{points}</p>
                )}
            </div>
        )
    }
}

export default User
