import PlayComponent from 'components/Play'
import PlayEngine from 'hoc/PlayEngine'
import { withRouter } from 'hoc/withRouter'

const WrappedPlay = PlayEngine(PlayComponent)

export function Play() {
    const Component = withRouter(WrappedPlay)
    return <Component />
}

export default Play
