const AVATARS = [
    'alligator',
    'ant',
    'bat',
    'bear',
    'bee',
    'bird',
    'bull',
    'bulldog',
    'butterfly',
    'cat',
    'chicken',
    'cow',
    'crab',
    'crocodile',
    'deer',
    'dog',
    'donkey',
    'duck',
    'eagle',
    'elephant',
    'fish',
    'fox',
    'frog',
    'giraffe',
    'gorilla',
    'hippo',
    'horse',
    'insect',
    'lion',
    'monkey',
    'moose',
    'mouse',
    'owl',
    'panda',
    'penguin',
    'pig',
    'rabbit',
    'rhino',
    'rooster',
    'shark',
    'sheep',
    'snake',
    'tiger',
    'turkey',
    'turtle',
    'wolf',
]

export default AVATARS
