import * as React from 'react'

import { Loader } from 'components/Loader'
import { IQuestion } from 'interfaces/IQuestion'

import css from './TV.module.scss'

interface ITVProps {
    alternatives?: IQuestion['alternatives']
    onChoice(choice: number): any
}

export class TV extends React.Component<ITVProps> {
    render() {
        const { props } = this

        if (!Array.isArray(props.alternatives)) return <Loader />

        return (
            <div className={css.wrapper}>
                {props.alternatives.map((alternative, index: number) => (
                    <div
                        className={css.alternative}
                        data-choice={index}
                        onClick={() => this.props.onChoice(index)}
                        key={index + alternative.name}
                    >
                        <p>
                            <strong>{alternative.artists.join(',')}</strong>
                        </p>
                    </div>
                ))}
            </div>
        )
    }
}

export default TV
