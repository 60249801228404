import * as React from 'react'

import css from './Modal.module.scss'

export const Modal: React.SFC = ({ children }) => (
    <div className={css.container} id="modal">
        <div className={css.modal}>{children}</div>
    </div>
)

export default Modal
