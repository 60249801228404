export function random(array: any[]) {
    if (!Array.isArray(array)) return console.error(array, 'is not an array')
    if (!array.length) return console.error(array, 'is empty')

    if (array.length > 1) {
        return array[Math.floor(Math.random() * array.length)]
    }

    return array[0]
}
