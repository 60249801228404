import * as React from 'react'

import { Loader } from 'components/Loader'

import css from './TrackPlayer.module.scss'

interface ITrackPlayerProps {
    dev?: boolean
    track: string
    durations: {
        voting: number
        fade: number
        stop: number
    }
    onStart(element: HTMLAudioElement): any
}

interface ITrackPlayerState {
    ready: boolean
    voting: boolean
    fading: boolean
    ending: boolean
    countdown?: number
}

class TrackPlayer extends React.Component<
    ITrackPlayerProps,
    ITrackPlayerState
> {
    static defaultProps: Partial<ITrackPlayerProps> = {
        dev: false,
    }

    state: ITrackPlayerState = {
        ready: false,
        voting: true,
        fading: true,
        ending: true,
    }
    audio: HTMLAudioElement | undefined = undefined
    timers: number[] = []
    fade: number = 0

    componentDidMount() {
        if (this.props.track && !this.props.dev) {
            this.initTrack(this.props.track)
        }
    }

    UNSAFE_componentWillReceiveProps(props: ITrackPlayerProps) {
        if (props.track === this.props.track) {
            return
        }

        this.setState({
            ready: false,
        })

        this.initTrack(props.track)
    }

    componentWillUnmount() {
        if (this.audio) {
            if (this.audio.pause) {
                this.audio.pause()
            }
            if (this.audio.remove) {
                this.audio.remove()
            }
        }
    }

    _handleInitClick = () => this.initTrack(this.props.track)

    initTrack(track: string) {
        if (this.audio) {
            if (this.audio.pause) {
                this.audio.pause()
            }
            if (this.audio.remove) {
                this.audio.remove()
            }

            delete this.audio

            this.audio = undefined
        }

        if (this.timers) {
            this.timers.map((timer) => window.clearTimeout(timer))
        }

        this.timers = []
        this.audio = new Audio(track)
        this.audio.volume = 0
        this.audio.oncanplaythrough = this._handleCanPlayThrough
        this.audio.onplay = this.props.onStart(this.audio)
    }

    _onEnded = () => {
        if (this.audio) {
            this.audio.pause()
            this.audio.remove()
        }

        delete this.audio
        this.audio = undefined
    }

    _handleCanPlayThrough = (e: Event) => {
        const { durations } = this.props
        e.preventDefault()

        this.setState({ ready: true })

        if (this.audio) {
            this.audio.play()
        }

        this.fadeIn()

        // TODO: Refactor timers into props?
        this.timers.push(
            window.setTimeout(() => {
                this.fadeVolume(0.4, Math.floor(durations.fade * 0.625))
            }, durations.voting),
        )

        this.timers.push(
            window.setTimeout(() => {
                this.fadeVolume(0, Math.floor(durations.stop * 0.8))
            }, durations.voting + durations.fade),
        )

        this.timers.push(
            window.setTimeout(() => {
                this._onEnded()
            }, durations.voting + durations.fade + durations.stop),
        )
    }

    fadeVolume = (volume: number, duration?: number) => {
        const audio = this.audio

        if (!audio) {
            return
        }

        if (!duration) {
            audio.volume = volume
        } else {
            let diff = parseInt('' + Math.abs(audio.volume - volume) * 100)
            const timeout = duration / diff

            const interval = window.setInterval(() => {
                if (diff <= 0) {
                    return window.clearInterval(interval)
                }

                if (audio.volume > volume) {
                    if (audio.volume < 0.011) {
                        audio.volume = 0
                    } else {
                        audio.volume -= 0.01
                    }
                } else {
                    if (audio.volume > 0.89) {
                        audio.volume = 1
                    } else {
                        audio.volume += 0.01
                    }
                }

                diff--
            }, timeout)
        }
    }

    fadeIn = () => {
        this.fade = window.setInterval(this._setVolume.bind(this, true), 10)
    }

    fadeOut = () => {
        this.fade = window.setInterval(this._setVolume.bind(this, false), 50)
    }

    _setVolume = (fadein: boolean) => {
        if (!this.audio) {
            return
        }

        let volume = this.audio.volume * 100

        if (fadein) {
            volume += 1
        } else {
            volume -= 1
        }

        if (volume > 100 || volume < 0) {
            if (volume > 100) {
                volume = 100
            }
            if (volume < 0) {
                volume = 0
            }

            return window.clearInterval(this.fade)
        }

        this.audio.volume = volume / 100
    }

    _handlePlay = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()

        if (this.audio) {
            this.audio.play()
        }
    }

    _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState((state) => ({
            ...state,
            [e.currentTarget.name]: e.currentTarget.checked,
        }))

    render() {
        const { ready, countdown } = this.state
        const { dev } = this.props

        return (
            <div>
                {dev && (
                    <div className={css.dev}>
                        <button
                            className="button small"
                            onClick={this._handleInitClick}
                        >
                            Init
                        </button>
                        <small>
                            <input
                                checked={this.state.voting}
                                name="voting"
                                onChange={this._handleOnChange}
                                type="checkbox"
                            />{' '}
                            Voting
                        </small>
                        <small>
                            <input
                                checked={this.state.fading}
                                name="fading"
                                onChange={this._handleOnChange}
                                type="checkbox"
                            />{' '}
                            Fading
                        </small>
                        <small>
                            <input
                                checked={this.state.ending}
                                name="ending"
                                onChange={this._handleOnChange}
                                type="checkbox"
                            />{' '}
                            Ending
                        </small>
                    </div>
                )}
                {!ready && !dev && <Loader onClick={this._handlePlay} />}
                {ready && countdown && (
                    <p className={css.countdown}>{countdown}</p>
                )}
            </div>
        )
    }
}

export default TrackPlayer
