import cn from 'classnames'
import * as React from 'react'

import { saveGame } from 'utils/firebase'

import { Join } from 'containers/Join'

import { IGame } from 'interfaces/IGame'
import { IWithRouterProps, withRouter } from 'hoc/withRouter'

import css from './Lobby.module.scss'

interface ILobbyState {
    joinView: boolean
}

class Lobby extends React.Component<IWithRouterProps, ILobbyState> {
    state = {
        joinView: false,
    }

    handleCreateClick = () =>
        saveGame().then((res: { game: IGame; id: string }) =>
            this.props.router.navigate(`/game/${res.id}`),
        )

    handleJoinClick = () => this.setState({ joinView: true })

    render() {
        const { state } = this

        return (
            <div
                className={cn(css.lobby, {
                    [css.joinView]: state.joinView,
                })}
            >
                <div className={css.panes}>
                    <button
                        className={cn(css.pane, css.create)}
                        onClick={this.handleCreateClick}
                    >
                        Create
                    </button>
                    <button
                        className={cn(css.pane, css.join)}
                        onClick={this.handleJoinClick}
                    >
                        Join
                    </button>
                </div>
                {state.joinView && (
                    <Join
                        className={css.joinComponent}
                        onCancel={() => this.setState({ joinView: false })}
                    />
                )}
            </div>
        )
    }
}

export const LobbyWithRouter = withRouter(Lobby)
