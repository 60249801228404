import * as React from 'react'

import { Button } from 'components/Button'
import Scoreboard from 'components/Scoreboard'
import { IGame } from 'interfaces/IGame'
import { IRounds } from 'interfaces/IRounds'

import css from './GameFinished.module.scss'

interface IGameFinishedProps {
    mainMenu: () => any
    newGame: () => any
    game: IGame
    rounds: IRounds
}

class GameFinished extends React.Component<IGameFinishedProps> {
    render() {
        const { mainMenu, game, rounds, newGame } = this.props

        return (
            <>
                <Button
                    className={css.mainMenu}
                    onClick={mainMenu}
                    size="small"
                >
                    Main menu
                </Button>
                <div className={css.newGame}>
                    <Button color="green" onClick={newGame}>
                        New game
                    </Button>
                </div>
                <Scoreboard
                    game={game}
                    key="score"
                    rounds={rounds}
                    score="total"
                />
            </>
        )
    }
}

export default GameFinished
