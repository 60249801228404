import * as React from 'react'
import { Routes, Route } from 'react-router-dom'

import { Rings } from 'components/Rings'
import { AuthWithRouter } from 'containers/Auth'
import { Game } from 'containers/Game'
import { LobbyWithRouter } from 'containers/Lobby'
import { User } from 'firebase/auth'
import QuickFire from 'containers/QuickFire'
import { Join } from 'containers/Join'
import Profile from 'containers/Profile'
import Play from 'containers/Play'
import Admin from 'containers/Admin'

import css from './Main.module.scss'

interface IMainState {
    authenticated: boolean
}

class Main extends React.Component<{}, IMainState> {
    state: IMainState = {
        authenticated: false,
    }

    handleAuth = (user: User | null) => this.setState({ authenticated: !!user })

    render() {
        const { authenticated } = this.state

        return (
            <div className={css.app}>
                <Rings />
                <AuthWithRouter onAuth={this.handleAuth} />
                {authenticated && (
                    <main className={css.content}>
                        <Routes>
                            <Route element={<LobbyWithRouter />} path="/" />
                            <Route element={<Game />} path="/game/:id" />
                            <Route
                                element={<QuickFire />}
                                path="/quickfire/:id"
                            />
                            <Route element={<Join />} path="/join" />
                            <Route element={<Profile />} path="/profile" />
                            <Route element={<Play />} path="/play/:id" />
                            <Route element={<Admin />} path="/admin" />
                        </Routes>
                    </main>
                )}
            </div>
        )
    }
}

export default Main
