import cn from 'classnames'
import * as React from 'react'

import css from './Track.module.scss'

export const Track: React.SFC<{
    className?: string
    artists?: string[]
    name?: string
    image?: string
    type?: 'standard' | 'list'
}> = ({ className, name, artists, image, type = 'standard' }) => (
    <div
        className={cn(className, css.track, {
            [css.standard]: type === 'standard',
            [css.list]: type === 'list',
        })}
    >
        <div className={css.imageWrapper}>
            {image && [
                <img className={css.shadow} key="shadow" src={image} alt="" />,
                <img className={css.image} key="image" src={image} alt="" />,
            ]}
            {!image && [
                <img
                    className={css.shadow}
                    key="shadow"
                    src="/img/cover.png"
                    alt=""
                />,
                <img
                    className={css.image}
                    key="image"
                    src="/img/cover.png"
                    alt=""
                />,
            ]}
        </div>
        <div className={css.details}>
            <div className={css.name}>{name}</div>
            {artists && <div className={css.artists}>{artists.join(', ')}</div>}
        </div>
    </div>
)

Track.defaultProps = {
    type: 'standard',
}

Track.propTypes = {}

export default Track
