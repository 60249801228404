import cn from 'classnames'
import * as React from 'react'

import css from './Avatar.module.scss'

export let AVATARS: { [key: string]: string } = {}

interface IAvatarProps {
    avatar?: string
    className?: string
}

export const Avatar: React.SFC<IAvatarProps> = ({ avatar, className }) =>
    avatar ? (
        <img
            alt={avatar}
            className={cn(className, css.avatar)}
            src={require('../../avatars/' + avatar + '.png')}
        />
    ) : null
