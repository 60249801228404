import { Link } from 'react-router-dom'
import React from 'react'
import {
    AuthCredential,
    FacebookAuthProvider,
    getAuth,
    getRedirectResult,
    linkWithCredential,
    signInWithRedirect,
    User,
} from 'firebase/auth'
import {
    DatabaseReference,
    DataSnapshot,
    get,
    getDatabase,
    off,
    onChildAdded,
    onChildChanged,
    ref,
    update,
} from 'firebase/database'

import { Avatar, AVATARS } from 'components/Avatar'
import { Icon } from 'components/Icon'
import { Loader } from 'components/Loader'
import Panel from 'components/Panel'

import css from './Profile.module.scss'

interface IProfileState {
    name: string
    avatar: string
}

class Profile extends React.Component<{}, IProfileState> {
    state: IProfileState = {
        name: '',
        avatar: '',
    }
    user: User | null = null
    ref?: DatabaseReference

    componentDidMount() {
        this.user = getAuth().currentUser

        if (!this.user) {
            throw new Error('No user')
        }

        this.ref = ref(getDatabase(), `users/${this.user.uid}`)
        get(this.ref).then((data) => this.setState({ ...data.val() }))
        onChildAdded(this.ref, this.updateUser)
        onChildChanged(this.ref, this.updateUser)

        getRedirectResult(getAuth()).catch((error) => {
            // const credential = error.credential;
            // if (credential.provider === "facebook.com") {
            //   var provider = new FacebookAuthProvider();
            //   provider.setCustomParameters({
            //     credential: credential.
            //   })
            //   this._linkAccount(
            //     new FacebookAuthProvider().credential(
            //       credential.accessToken
            //     )
            //   );
            // }
        })
    }

    componentWillUnmount() {
        if (this.ref) off(this.ref)
    }

    updateUser = (data: DataSnapshot) =>
        data.key &&
        this.setState &&
        this.setState({
            ...this.state,
            [data.key as keyof IProfileState]: data.val(),
        })

    _handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    ) => {
        if (this.ref) update(this.ref, { [e.target.name]: e.target.value })
    }

    _linkAccount = (credential: AuthCredential) => {
        if (!this.user) {
            throw new Error('No user')
        }

        linkWithCredential(this.user, credential).then(
            (user) => console.info('Account linking success', user),
            (error) => console.info('Account linking error', error),
        )
    }

    _linkFacebook = () =>
        signInWithRedirect(getAuth(), new FacebookAuthProvider())
    // _linkGoogle = () => getAuth().signInWithRedirect(new firebase.auth.GoogleAuthProvider())
    // _linkTwitter = () => getAuth().signInWithRedirect(new firebase.auth.TwitterAuthProvider())

    render() {
        const { name, avatar } = this.state

        if (!this.user) {
            return <Loader />
        }

        const facebookLinked = this.user.providerData.find((p) =>
            p ? p.providerId === 'facebook.com' : false,
        )

        const linkedProviders = facebookLinked
        const linkableProviders = !facebookLinked

        return (
            <div>
                <Link className="back" to="/">
                    Back to lobby
                </Link>
                <Panel>
                    <p>
                        <input
                            id="nickname"
                            name="name"
                            onChange={this._handleChange}
                            placeholder="Nickname"
                            type="text"
                            value={name}
                        />
                    </p>
                    <hr />
                    {avatar && (
                        <p className={css.avatar}>
                            <Avatar avatar={avatar} />
                        </p>
                    )}
                    <select
                        name="avatar"
                        onChange={this._handleChange}
                        placeholder="Avatar"
                        value={avatar}
                    >
                        {Object.keys(AVATARS).map((a) => (
                            <option key={a} value={a}>
                                {a}
                            </option>
                        ))}
                    </select>
                    {linkedProviders && [
                        <hr key="divider" />,
                        <p key="providers">
                            <label>Linked providers</label>
                            {facebookLinked && (
                                <Icon className={css.linked} icon="facebook" />
                            )}
                        </p>,
                    ]}
                    {linkableProviders && [
                        <hr key="divider" />,
                        <p key="providers">
                            <label>Linkable providers</label>
                            {!facebookLinked && (
                                <button
                                    type="button"
                                    onClick={this._linkFacebook}
                                >
                                    <Icon icon="facebook" />
                                </button>
                            )}
                        </p>,
                    ]}
                </Panel>
            </div>
        )
    }
}

export default Profile
