import * as React from 'react'

import User from 'components/User'

import { calculcatePoints } from 'utils/points'

import { IGame } from 'interfaces/IGame'
import { IQuestion } from 'interfaces/IQuestion'
import { IRound } from 'interfaces/IRound'
import { getApps } from 'firebase/app'
import { child, get, getDatabase, ref } from 'firebase/database'

import css from './MiniScoreboard.module.scss'

interface IMiniScoreboardProps {
    game: IGame
}

interface IMiniScoreboardState {
    rounds?: {
        [key: string]: IRound
    }
}

export class MiniScoreboard extends React.Component<
    IMiniScoreboardProps,
    IMiniScoreboardState
> {
    state: IMiniScoreboardState = {}

    componentDidMount() {
        if (this.props.game.rounds) {
            if (!getApps().length) {
                return
            }

            const roundRef = ref(getDatabase(), '/rounds')

            Object.keys(this.props.game.rounds).forEach((round) => {
                get(child(roundRef, round)).then((snapshot) => {
                    if (snapshot) {
                        this.setState((state) => ({
                            rounds: {
                                ...state.rounds,
                                [snapshot.key || '']: snapshot.val(),
                            },
                        }))
                    }
                })
            })
        }
    }

    get points() {
        if (!this.state.rounds) {
            return []
        }

        const score: 'question' | 'total' = 'total'
        const rounds = this.state.rounds
        // const round = rounds[this.props.game.round]

        let points: Array<{ user: string; points: number }> = []

        let allQuestions: IQuestion[] = []

        Object.keys(rounds).forEach((r) => {
            const questions = rounds[r].questions
            if (Array.isArray(questions)) {
                allQuestions = [...allQuestions, ...questions]
            }
        })

        switch (score) {
            // case 'question':
            //     points = calculcatePoints([round.questions[round.question]])
            //     break

            case 'total':
                points = calculcatePoints(allQuestions)
                break
        }

        return points
    }

    render() {
        const { props } = this

        if (!props.game.players) {
            return 'Loading...'
        }

        return (
            <div className={css.scoreboard}>
                <table>
                    <tbody>
                        {this.points.map((player) => (
                            <tr key={player.user}>
                                <td>
                                    <User
                                        showAvatar={false}
                                        id={player.user}
                                        points={player.points}
                                        size="small"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default MiniScoreboard
