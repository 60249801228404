import { initializeApp } from 'firebase/app'

const firebaseConfig = {
    apiKey: 'AIzaSyAd6_KiL_Jhi6g_c4MOD5KojWSwVZjsZ1Q',
    authDomain: 'yamboo-316a5.firebaseapp.com',
    databaseURL: 'https://yamboo-316a5.firebaseio.com',
    projectId: 'yamboo-316a5',
    storageBucket: 'yamboo-316a5.appspot.com',
    messagingSenderId: '877597931559',
    appId: '1:877597931559:web:545673f0395f6de9ef3602',
}

export const firebase = initializeApp(firebaseConfig)
