import * as React from 'react'

import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Loader } from 'components/Loader'
import { RadioGroup } from 'components/RadioGroup'

import GAMEMODE from 'constants/gamemode'
import { IGame } from 'interfaces/IGame'

interface IGameModeProps {
    game?: IGame
    onSubmit?: (state: IGameModeState) => any
    uid?: string
}

interface IGameModeState {
    mode: GAMEMODE
    rounds: number
    questions: number
    loading: boolean
    TV: boolean
}

export class GameMode extends React.Component<IGameModeProps, IGameModeState> {
    state: IGameModeState = {
        mode: GAMEMODE.GENRE,
        rounds: 1,
        questions: 8,
        loading: false,
        TV: false,
    }

    _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget

        switch (name) {
            case 'rounds':
                const rounds = parseInt(value)
                if (rounds < 1 || rounds > 5) return
                this.setState({ rounds })
                break

            case 'questions':
                const questions = parseInt(value)
                if (questions < 1 || questions > 8) return
                this.setState({ questions })
                break

            case 'TV':
                this.setState({ TV: value === 'true' })
                break

            case 'mode':
                this.setState({ mode: value as GAMEMODE })
                break

            default:
                throw new Error(`Unhandled change event for name: ${name}`)
        }
    }

    _handleOnSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        if (this.props.onSubmit) {
            this.props.onSubmit(this.state)
            this.setState({ loading: true })
        }
    }

    render() {
        const { uid } = this.props
        const { loading, mode, TV, rounds, questions } = this.state

        const modes: { [key: string]: string } = {
            [GAMEMODE.GENRE]: 'Genre',
            [GAMEMODE.PLAYLIST]: 'Playlist',
        }

        // TODO: check for dis betterer
        if (uid === '4n4s36iJbCMAvO59YQahxjOFH7z2') {
            modes[GAMEMODE.QUICK_FIRE] = 'Quick Fire'
        }

        return (
            <Form onSubmit={this._handleOnSubmit} panel={true}>
                <RadioGroup
                    name="mode"
                    onChange={this._handleOnChange}
                    title="Mode"
                    value={mode}
                    values={modes}
                    vertical={Object.keys(modes).length >= 3}
                />
                {mode === GAMEMODE.QUICK_FIRE && (
                    <RadioGroup
                        name="TV"
                        onChange={this._handleOnChange}
                        title="TV"
                        value={JSON.stringify(TV)}
                        values={{
                            false: 'No',
                            true: 'Yes',
                        }}
                    />
                )}
                <Input
                    name="rounds"
                    onChange={this._handleOnChange}
                    placeholder="Rounds (1-5)"
                    type="number"
                    value={rounds}
                />
                {mode !== GAMEMODE.QUICK_FIRE && (
                    <Input
                        name="questions"
                        onChange={this._handleOnChange}
                        placeholder="Questions (1-8)"
                        type="number"
                        value={questions}
                    />
                )}
                <Button
                    center={true}
                    color="purple"
                    disabled={loading}
                    onClick={this._handleOnSubmit}
                    type="submit"
                >
                    Next
                </Button>
                {loading && <Loader />}
            </Form>
        )
    }
}
