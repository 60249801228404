import * as React from 'react'

import cn from 'classnames'

import css from './Input.module.scss'

interface IInputProps {
    id?: string
    type: 'radio' | 'text' | 'number'
    name: string
    value: string | number
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    placeholder?: string
    groupClassName?: string
    maxLength?: number
    className?: string
}

export class Input extends React.Component<IInputProps> {
    _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.props.onChange && this.props.onChange(e)

    render() {
        const { groupClassName, id, value, ...inputProps } = this.props

        const radio = inputProps.type === 'radio'

        return (
            <div
                className={cn(css.group, groupClassName, {
                    [css.radio]: radio,
                })}
            >
                {inputProps.placeholder && !radio && (
                    <label
                        className={cn(css.label, {
                            [css.hide]: !value,
                        })}
                        htmlFor={id}
                    >
                        {inputProps.placeholder}
                    </label>
                )}
                <input
                    {...inputProps}
                    id={id}
                    value={value}
                    onChange={this._handleOnChange}
                />
                {radio && (
                    <span className={css.radioLabel}>
                        {inputProps.placeholder}
                    </span>
                )}
            </div>
        )
    }
}
