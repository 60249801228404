import cn from 'classnames'
import * as React from 'react'

import Ring from 'components/Ring'
import { IChoice } from 'interfaces/IChoice'

import css from './Choices.module.scss'

interface IChoicesProps {
    duration: number
    choices: IChoice[]
    onComplete?: () => any
}

interface IChoicesState {
    alert: boolean
}

export class Choices extends React.PureComponent<IChoicesProps, IChoicesState> {
    static defaultProps: Partial<IChoicesProps> = {
        duration: 10000,
    }

    state: IChoicesState = {
        alert: false,
    }

    timeout: number = 0
    onComplete: number = 0

    componentDidMount() {
        const { props } = this

        this.timeout = window.setTimeout(
            () => this.setState({ alert: true }),
            props.duration * 0.75,
        )

        this.onComplete = window.setTimeout(
            () => props.onComplete && props.onComplete(),
            props.duration,
        )
    }

    componentWillUnmount() {
        window.clearTimeout(this.timeout)
        window.clearTimeout(this.onComplete)
    }

    _ringColor = (index: number) => {
        switch (index) {
            case 3:
                return 'purple'

            case 2:
                return 'blue'

            case 1:
                return 'orange'

            case 0:
            default:
                return 'green'
        }
    }

    render() {
        const { choices, duration } = this.props
        const { alert } = this.state

        if (!choices) return null

        return (
            <div className={css.choices}>
                {choices.map((choice, i) => (
                    <div className={css.choice} key={i}>
                        <Ring
                            color={this._ringColor(i)}
                            text={choice.artists.join(', ')}
                        />
                    </div>
                ))}
                <div
                    className={cn(css.countdown, {
                        [css.alert]: alert,
                    })}
                >
                    <svg
                        className={cn(css.circle, css.animation)}
                        height="85"
                        style={{ animationDuration: `${duration}ms` }}
                        width="85"
                    >
                        <circle
                            cx="43"
                            cy="43"
                            fill="none"
                            r="39"
                            stroke={alert ? '#f00' : '#4f75d8'}
                            strokeWidth="3"
                        />
                    </svg>
                    <svg
                        className={cn(
                            css.circle,
                            css.circleShadow,
                            css.animation,
                        )}
                        height="85"
                        style={{ animationDuration: `${duration}ms` }}
                        width="85"
                    >
                        <circle
                            cx="43"
                            cy="43"
                            fill="none"
                            r="39"
                            stroke={alert ? '#f00' : '#4f75d8'}
                            strokeWidth="6"
                        />
                    </svg>
                </div>
            </div>
        )
    }
}

export default Choices
