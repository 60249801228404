import * as React from 'react'

import css from './QuickFirePlayer.module.scss'

interface IQuickFirePlayerProps {
    cover?: string
    name: string
    artists: string[]
    previewUrl?: string
    onPlay(): any
}

class QuickFirePlayer extends React.Component<IQuickFirePlayerProps> {
    audio: HTMLAudioElement | null = null

    render() {
        const { props } = this

        return (
            <div className={css.player}>
                <div className={css.song}>
                    {props.cover && (
                        <img className={css.cover} src={props.cover} alt="" />
                    )}
                    <div>
                        <p className={css.name}>{props.name}</p>
                        <p className={css.artists}>
                            {props.artists.join(', ')}
                        </p>
                    </div>
                </div>
                <audio
                    className={css.audio}
                    controls
                    src={props.previewUrl}
                    ref={(node) => (this.audio = node)}
                    onPlay={props.onPlay}
                />
            </div>
        )
    }
}

export default QuickFirePlayer
