import * as React from 'react'

import { IGame } from 'interfaces/IGame'
import { joinGameById } from 'utils/firebase'
import {
    DatabaseReference,
    DataSnapshot,
    get,
    getDatabase,
    off,
    onChildAdded,
    onChildChanged,
    ref,
    serverTimestamp,
    set,
    update,
} from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { IWithRouterProps } from 'hoc/withRouter'

export enum VOTING_STATES {
    VOTE = 'VOTE',
    VOTING = 'VOTING',
    VOTED = 'VOTED',
    WAITING = 'WAITING',
}

interface IPlayEngineState {
    state?: VOTING_STATES
    game?: Partial<IGame>
    question?: number
    started?: number
    finished?: number
    uid?: string
}

export const PlayEngine = (ComposedComponent: typeof React.Component) =>
    class extends React.Component<IWithRouterProps, IPlayEngineState> {
        static displayName = 'PlayEngine'
        state: IPlayEngineState = {
            state: VOTING_STATES.VOTE,
            question: 0,
        }
        ref: DatabaseReference = {} as DatabaseReference

        constructor(props: IWithRouterProps) {
            super(props)

            const { currentUser } = getAuth()

            if (currentUser) {
                this.state.uid = currentUser.uid
            }
        }

        componentDidMount() {
            const { id } = this.props.router.params
            this.ref = ref(getDatabase(), `games/${id}`)
            this.addListeners()
        }

        componentDidUpdate(prevProps: IWithRouterProps) {
            if (prevProps.router.params.id !== this.props.router.params.id) {
                this.handleNewGame(this.props.router.params.id)
            }
        }

        componentWillUnmount() {
            this.removeListeners()
        }

        addListeners() {
            get(this.ref).then((data) => {
                this.setState({ game: data.val() })
            })
            onChildAdded(this.ref, this.updateGame)
            onChildChanged(this.ref, this.updateGame)
        }

        removeListeners() {
            off(this.ref)
        }

        handleNewGame(id?: string) {
            if (!id) return
            this.removeListeners()
            this.setState(
                {
                    state: VOTING_STATES.VOTE,
                    question: 0,
                    finished: undefined,
                    started: undefined,
                    game: undefined,
                },
                () => {
                    this.ref = ref(getDatabase(), `games/${id}`)
                    this.addListeners()
                },
            )
        }

        updateGame = (data: DataSnapshot) => {
            if (data.key === 'round') {
                const reference = ref(getDatabase(), `rounds/${data.val()}`)

                get(reference).then((snapshot) =>
                    this.setState({
                        question: snapshot.val().question || 0,
                        started: snapshot.val().started,
                        finished: snapshot.val().finished,
                    }),
                )
                onChildChanged(reference, this.updateRound)
                onChildAdded(reference, this.updateRound)
            }

            this.setState((state) => ({
                game: {
                    ...state.game,
                    [data.key as string]: data.val(),
                },
            }))
        }

        _handleChoice = (choice: number | string) => {
            const { game, question, state, uid } = this.state

            // Only proceed if we are in a "vote possible state"
            if (
                state === VOTING_STATES.VOTED ||
                state === VOTING_STATES.VOTING ||
                !game
            ) {
                return
            }

            if (typeof question !== 'number') {
                return alert(question + ' is undefined')
            }

            this.setState({ state: VOTING_STATES.VOTING })

            set(
                ref(
                    getDatabase(),
                    `rounds/${game.round}/questions/${question}/selections/${uid}`,
                ),
                {
                    choice:
                        typeof choice === 'string' ? parseInt(choice) : choice,
                    timestamp: serverTimestamp(),
                },
            ).then(() => this.setState({ state: VOTING_STATES.VOTED }))
        }

        _handleLeave = () => {
            const { id } = this.props.router.params
            const { uid } = this.state

            const updates: { [key: string]: any } = {}
            updates[`/games/${id}/players/${uid}`] = null
            updates[`/users/${uid}/games/${id}`] = null
            update(ref(getDatabase()), updates)

            // Fix some day
            window.location.href = '/'
        }

        handleJoinGame = async () => {
            const id = this.state.game && this.state.game.newGame

            if (id) {
                const joined = await joinGameById(id)

                if (joined) {
                    return this.props.router.navigate(`/play/${id}`)
                }

                alert('Error joining game')
            }
        }

        _handleStartGame = () => {
            const { game } = this.state
            const { uid } = this.state

            if (!game || (!game.admin && game.admin !== uid)) return

            set(
                ref(getDatabase(), `rounds/${game.round}/started`),
                serverTimestamp(),
            )
        }

        updateRound = (data: DataSnapshot) => {
            const keys = ['question', 'started', 'finished']

            if (data.key && keys.includes(data.key)) {
                this.setState({
                    state: VOTING_STATES.VOTE,
                    [data.key as string]: data.val(),
                })
            }
        }

        render() {
            return (
                <ComposedComponent
                    {...this.props}
                    {...this.state}
                    onChoice={this._handleChoice}
                    onLeave={this._handleLeave}
                    onStartGame={this._handleStartGame}
                    onJoinGame={this.handleJoinGame}
                />
            )
        }
    }

export default PlayEngine
