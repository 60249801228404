import React from 'react'
import { get, getDatabase, ref } from 'firebase/database'
import cn from 'classnames'

import { Button } from 'components/Button'
import { Input } from 'components/Input'
import { Loader } from 'components/Loader'
import RadioGroup from 'components/RadioGroup'
import { ISpotifyPlaylist } from 'interfaces/ISpotifyPlaylist'
import {
    getCategories,
    getGenres,
    getPlaylistsForGenre,
    getUserPlaylists,
} from '../../api'

import css from './PlaylistPicker.module.scss'
import Select from 'components/Select'

enum PlaylistView {
    User = 'User',
    Genre = 'Genre',
    Popular = 'Popular',
    Category = 'Category',
}

interface IPlaylistPickerProps {
    setPlaylist(playlist: { uid?: string; pid?: string }): void
}

interface IPlaylistPickerState {
    playlists: ISpotifyPlaylist[]
    popularPlaylists?: {
        [pid: string]: string
    }
    username: string
    mode: 'user' | 'popular'
    uid?: string
    pid?: string
    searching: boolean
    error?: Error
    special: boolean
    view: PlaylistView
    categoryId: string
    genres: string[]
    genrePlaylists: unknown[]
    categories: unknown[]
    categoryPlaylists: unknown[]
}

const sequence = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]

class PlaylistPicker extends React.Component<
    IPlaylistPickerProps,
    IPlaylistPickerState
> {
    state: IPlaylistPickerState = {
        playlists: [],
        username: '',
        mode: 'user',
        uid: '',
        searching: false,
        special:
            window && window.sessionStorage
                ? !!window.sessionStorage.getItem('konami')
                : false,
        view: PlaylistView.User,
        categoryId: '',
        genres: [],
        genrePlaylists: [],
        categories: [],
        categoryPlaylists: [],
    }

    key: number = 0

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown)
    }

    handleKeyDown = (e: KeyboardEvent) => {
        if (e.metaKey || e.altKey || e.ctrlKey) return

        if (sequence[this.key] === e.keyCode) {
            this.key++
        } else {
            this.key = 0
        }

        if (this.key === 10) {
            this.setState({ special: true }, () => {
                window.sessionStorage.setItem('konami', 'true')
                document.removeEventListener('keydown', this.handleKeyDown)
            })
        }
    }

    _fetchPlaylists = () => {
        const { username } = this.state
        if (!username) return

        this.setState({ searching: true })

        getUserPlaylists(username)
            .then(
                (playlists) =>
                    this.setState &&
                    this.setState({ playlists, searching: false }),
            )
            .catch(
                (error) =>
                    this.setState && this.setState({ error, searching: false }),
            )
    }

    _setPlaylist = (e: React.MouseEvent<HTMLElement>) =>
        this.setState(
            {
                uid: e.currentTarget.dataset.uid,
                pid: e.currentTarget.dataset.pid,
            },
            () =>
                this.props.setPlaylist({
                    uid: this.state.uid,
                    pid: this.state.pid,
                }),
        )

    _handleUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ username: e.currentTarget.value })

    _handleViewChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState(
            {
                view: e.currentTarget.value as IPlaylistPickerState['view'],
            },
            () => {
                switch (this.state.view) {
                    case PlaylistView.Popular:
                        this.fetchPopularPlaylists()
                        break

                    case PlaylistView.Genre:
                        this.fetchGenres()
                        break

                    case PlaylistView.Category:
                        this.fetchCategories()
                        break
                }
            },
        )

    fetchPopularPlaylists = () => {
        get(ref(getDatabase(), 'playlists')).then((snapshot) => {
            const data = snapshot.val()

            if (data) this.setState({ popularPlaylists: data })
        })
    }

    fetchGenres = async () => this.setState({ genres: await getGenres() })

    fetchPlaylistsForGenre = async () => {
        const { categoryId } = this.state

        if (!categoryId) return

        const playlists = await getPlaylistsForGenre(categoryId)

        if (playlists) this.setState({ genrePlaylists: playlists.items })
    }

    fetchCategories = async () =>
        this.setState({ categories: await getCategories() })

    fetchPlaylistsForCategory = async (id: string) => {
        if (!id) return

        const playlists = await getPlaylistsForGenre(id)

        if (playlists) this.setState({ categoryPlaylists: playlists.items })
    }

    render() {
        const {
            playlists,
            popularPlaylists,
            username,
            pid,
            searching,
            special,
            view,
            categoryId,
            genres,
            genrePlaylists,
            categories,
            categoryPlaylists,
        } = this.state

        return (
            <div>
                {special && (
                    <RadioGroup
                        name="view"
                        onChange={this._handleViewChange}
                        title=""
                        value={view}
                        values={PlaylistView}
                        vertical
                    />
                )}
                {view === PlaylistView.User && (
                    <>
                        <div className={css.input}>
                            <Input
                                name="username"
                                onChange={this._handleUsernameChange}
                                placeholder="Username"
                                type="text"
                                value={username}
                            />
                            <Button
                                disabled={searching || !username.length}
                                onClick={this._fetchPlaylists}
                                type="button"
                            >
                                Search
                            </Button>
                        </div>
                        {Array.isArray(playlists) && playlists.length > 0 && (
                            <ul className={css.playlists}>
                                {playlists.map((p) => (
                                    <li key={p.id}>
                                        <button
                                            className={cn({
                                                [css.activePlaylist]:
                                                    pid === p.id,
                                            })}
                                            data-pid={p.id}
                                            data-uid={p.ownerUserName}
                                            onClick={this._setPlaylist}
                                            type="button"
                                        >
                                            {p.name}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
                {view === PlaylistView.Genre && (
                    <>
                        {genres.length === 0 && <Loader overlay={false} />}
                        {genres.length > 0 && (
                            <Select
                                name="genre"
                                value={categoryId}
                                onChange={(e) =>
                                    this.setState(
                                        { categoryId: e.target.value },
                                        this.fetchPlaylistsForGenre,
                                    )
                                }
                                placeholder="Pick a genre"
                            >
                                <option value=""></option>
                                {genres.map((g) => (
                                    <option key={g} value={g}>
                                        {g}
                                    </option>
                                ))}
                            </Select>
                        )}
                        {categoryId && !Array.isArray(genrePlaylists) && (
                            <Loader overlay={false} />
                        )}
                        {genrePlaylists && Array.isArray(genrePlaylists) && (
                            <div className={css.genres}>
                                {genrePlaylists.map((playlist) => (
                                    <button
                                        className={cn(css.genrePlaylist, {
                                            [css.dimmed]:
                                                pid && pid !== playlist.id,
                                        })}
                                        type="button"
                                        key={playlist.id}
                                        data-pid={playlist.id}
                                        data-uid={playlist.owner.id}
                                        onClick={this._setPlaylist}
                                    >
                                        {playlist.images &&
                                            playlist.images[0] && (
                                                <img
                                                    src={playlist.images[0].url}
                                                    alt={playlist.name}
                                                />
                                            )}
                                        <p>
                                            <strong>{playlist.name}</strong>
                                        </p>
                                    </button>
                                ))}
                            </div>
                        )}
                    </>
                )}
                {view === PlaylistView.Popular && (
                    <>
                        {!popularPlaylists && <Loader />}
                        {popularPlaylists && (
                            <ul className={css.playlists}>
                                {Object.keys(popularPlaylists).map((k) => (
                                    <li key={k}>
                                        <button
                                            className={cn({
                                                [css.activePlaylist]: pid === k,
                                            })}
                                            data-pid={k}
                                            data-uid=""
                                            onClick={this._setPlaylist}
                                            type="button"
                                        >
                                            {popularPlaylists[k]}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
                {view === PlaylistView.Category && (
                    <>
                        {!categories.length && <Loader overlay={false} />}
                        {categories && Array.isArray(categories) && (
                            <div
                                style={{
                                    display: categoryPlaylists.length
                                        ? 'none'
                                        : undefined,
                                }}
                                className={css.categories}
                            >
                                {categories.map((c) => (
                                    <button
                                        className={css.category}
                                        type="button"
                                        key={c.id}
                                        onClick={() =>
                                            this.fetchPlaylistsForCategory(c.id)
                                        }
                                    >
                                        {c.icons && c.icons[0] && (
                                            <img
                                                src={c.icons[0].url}
                                                alt={c.name}
                                            />
                                        )}
                                        <p>
                                            <strong>{c.name}</strong>
                                        </p>
                                    </button>
                                ))}
                            </div>
                        )}

                        {categoryPlaylists.length >= 1 &&
                            Array.isArray(categoryPlaylists) && (
                                <>
                                    <button
                                        className="back"
                                        onClick={() =>
                                            this.setState({
                                                categoryPlaylists: [],
                                            })
                                        }
                                    >
                                        Back
                                    </button>
                                    <div className={css.genres}>
                                        {categoryPlaylists.map((playlist) => (
                                            <button
                                                className={cn(
                                                    css.genrePlaylist,
                                                    {
                                                        [css.dimmed]:
                                                            pid &&
                                                            pid !== playlist.id,
                                                    },
                                                )}
                                                type="button"
                                                key={playlist.id}
                                                data-pid={playlist.id}
                                                data-uid={playlist.owner.id}
                                                onClick={this._setPlaylist}
                                            >
                                                {playlist.images &&
                                                    playlist.images[0] && (
                                                        <img
                                                            src={
                                                                playlist
                                                                    .images[0]
                                                                    .url
                                                            }
                                                            alt={playlist.name}
                                                        />
                                                    )}
                                                <p>
                                                    <strong>
                                                        <small>
                                                            {playlist.name}
                                                        </small>
                                                    </strong>
                                                </p>
                                            </button>
                                        ))}
                                    </div>
                                </>
                            )}
                    </>
                )}
                <span />
            </div>
        )
    }
}

export default PlaylistPicker
