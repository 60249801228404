import { createGameFromPlaylist } from 'api'
import { getAuth } from 'firebase/auth'
import {
    child,
    equalTo,
    get,
    getDatabase,
    orderByChild,
    push,
    query,
    ref,
    serverTimestamp,
    set,
    update,
} from 'firebase/database'
import { IGame } from 'interfaces/IGame'
import { IQuestion } from 'interfaces/IQuestion'

export function generateCode(
    length = 5,
    possible = 'ABCDEFGHJKLMNPQRSTUVWXYZ23456789',
) {
    let text = ''

    for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
}

export function saveGameFromPlaylist(owner: string, playlist: string) {
    return createGameFromPlaylist(owner, playlist)
        .then((json) => saveGame(json, { owner, playlist }))
        .catch((error) => console.error(error))
}

export function saveGame(
    game?: IGame,
    metadata = {},
): Promise<{ id: string; game: IGame }> {
    const { currentUser } = getAuth()

    if (!currentUser) {
        throw new Error('Not authorised')
    }

    const { uid } = currentUser

    const data: IGame = {
        ...game,
        ...metadata,
        code: generateCode(),
        created: serverTimestamp() as unknown as number,
        admin: uid,
        points: 'TIME',
    }

    return push(ref(getDatabase(), 'games'), data).then((reference) => ({
        game: data,
        id: reference.key || '',
    }))
}

export function addQuickFireQuestions(round: string, questions: IQuestion[]) {
    set(child(ref(getDatabase(), 'rounds'), round), {
        question: 0,
        started: false,
        questions,
    })
}

export async function joinGameByCode(code: string) {
    const auth = getAuth()

    if (auth.currentUser == null) {
        throw new Error('Login you hobo!')
    }

    const { uid } = auth.currentUser

    const games = (
        await get(
            query(
                ref(getDatabase(), 'games'),
                orderByChild('code'),
                equalTo(code),
            ),
        )
    ).val()

    if (games) {
        if (Object.keys(games).length > 1) {
            throw new Error('Too many games on the dance floor')
        }

        const id = Object.keys(games)[0]

        const updates: { [key: string]: any } = {}
        updates[`/games/${id}/players/${uid}`] = true
        updates[`/users/${uid}/games/${id}`] = true

        await update(ref(getDatabase()), updates)

        return id
    }

    return false
}

export async function joinGameById(id: string) {
    const auth = getAuth()

    if (auth.currentUser == null) {
        throw new Error('Login you hobo!')
    }

    const game = await get(ref(getDatabase(), `games/${id}`))

    if (game.val()) {
        const { uid } = auth.currentUser

        const updates: { [key: string]: any } = {}
        updates[`/games/${id}/players/${uid}`] = true
        updates[`/users/${uid}/games/${id}`] = true

        await update(ref(getDatabase()), updates)

        return true
    }

    return false
}
