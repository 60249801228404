import { createPortal } from 'react-dom'

import css from './Rings.module.scss'

export const Rings = () =>
    createPortal(
        <div className={css.rings} id="rings">
            <div className={css.ring} />
            <div className={css.ring} />
            <div className={css.ring} />
            <div className={css.ring} />
        </div>,
        document.body,
    )
