import anime from 'animejs'
import * as React from 'react'
import { Flipped, Flipper } from 'react-flip-toolkit'

import User from 'components/User'
import { calculcatePoints } from '../../utils/points'

import { IGame } from 'interfaces/IGame'
import { IQuestion } from 'interfaces/IQuestion'
import { IRounds } from 'interfaces/IRounds'

import css from './Scoreboard.module.scss'

export interface IScoreboardProps {
    game: IGame
    rounds: IRounds
    score: 'question' | 'total'
}

const animateElementIn = (el: HTMLElement, i: number) =>
    anime({
        targets: el,
        opacity: 1,
        delay: i * 10,
        easing: 'easeOutSine',
        duration: 200,
    })

const animateElementOut = (el: HTMLElement, i: number, onComplete: () => any) =>
    anime({
        targets: el,
        opacity: 0,
        delay: i * 10,
        easing: 'easeOutSine',
        complete: onComplete,
        duration: 200,
    })

export const Scoreboard: React.SFC<IScoreboardProps> = ({
    game,
    rounds,
    score,
}) => {
    if (typeof game.round === 'undefined') return <p>Missing round</p>

    const round = rounds[game.round]

    let points: Array<{ user: string; points: number }> = []

    switch (score) {
        case 'question':
            if (round.questions && typeof round.question === 'number') {
                points = calculcatePoints([round.questions[round.question]])
            }
            break

        case 'total':
            let allQuestions: IQuestion[] = []
            Object.keys(rounds).forEach((r) => {
                const questions = rounds[r].questions

                if (questions) {
                    allQuestions = [...allQuestions, ...questions]
                }
            })

            points = calculcatePoints(allQuestions)
            break
    }

    return (
        <div className={css.scoreboard}>
            <div>
                <Flipper
                    flipKey={points
                        .slice(0, 3)
                        .map((p) => p.user)
                        .join('')}
                    element="ul"
                    className={css.top}
                >
                    {points.slice(0, 3).map((p) => (
                        <Flipped
                            key={p.user}
                            flipId={p.user}
                            onAppear={animateElementIn}
                            onExit={animateElementOut}
                            stagger
                        >
                            <li>
                                <User
                                    id={p.user}
                                    points={p.points}
                                    size="small"
                                />
                            </li>
                        </Flipped>
                    ))}
                </Flipper>
                <Flipper
                    flipKey={points
                        .slice(0, 3)
                        .map((p) => p.user)
                        .join('')}
                    element="ul"
                    className={css.rest}
                >
                    {points.slice(3, points.length).map((p) => (
                        <Flipped
                            key={p.user}
                            flipId={p.user}
                            onAppear={animateElementIn}
                            onExit={animateElementOut}
                            stagger
                        >
                            <li>
                                <User
                                    showAvatar={false}
                                    id={p.user}
                                    points={p.points}
                                    size="small"
                                />
                            </li>
                        </Flipped>
                    ))}
                </Flipper>
            </div>
        </div>
    )
}

export default Scoreboard
