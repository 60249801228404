import POINTS from 'constants/points'
import { IQuestion } from 'interfaces/IQuestion'

export function calculcatePoints(questions: IQuestion[], type?: string) {
    switch (type) {
        case POINTS.STANDARD:
            return pointsByStandard(questions)

        case POINTS.TIME:
        default:
            return pointsByTime(questions)
    }
}

export function pointsByStandard(questions: IQuestion[]) {
    const users: { [user: string]: number } = {}

    if (!Array.isArray(questions)) return []

    questions.forEach((q) => {
        if (!q.selections) return
        Object.keys(q.selections).forEach((s) => {
            const selection = q.selections && q.selections[s]

            if (!selection) return

            const choice =
                typeof selection.choice === 'string'
                    ? parseInt(selection.choice)
                    : selection.choice

            if (choice === q.correctAlternative) {
                if (users[s]) {
                    users[s] = users[s] + 1
                } else {
                    users[s] = 1
                }
            }
        })
    })

    if (!Object.keys(users)) return []

    const points = Object.keys(users)
        .map((u) => ({
            user: u,
            points: users[u],
        }))
        .sort((a, b) => b.points - a.points)

    return points
}

export function pointByTime(
    started: number,
    answered?: number,
    limit: number = 10 * 1000,
    gracePeriod: number = 0,
) {
    if (typeof answered === 'undefined') return 0

    const time = limit + started - (answered - gracePeriod)
    const points = Math.ceil(time / 100)

    return points > 0 ? points : 0
}

export function pointsByTime(questions: IQuestion[]) {
    const users: { [user: string]: number } = {}

    if (!Array.isArray(questions)) return []

    questions.forEach((q) => {
        if (!q.selections) return
        Object.keys(q.selections).forEach((s) => {
            if (typeof q.started === 'undefined') return

            const selection = q.selections && q.selections[s]

            if (!selection) return

            if (!users[s]) users[s] = 0

            const choice =
                typeof selection.choice === 'string'
                    ? parseInt(selection.choice)
                    : selection.choice

            if (choice === q.correctAlternative) {
                users[s] += pointByTime(q.started, selection.timestamp)
            }
        })
    })

    if (!Object.keys(users)) return []

    const points = Object.keys(users)
        .map((u) => ({
            user: u,
            points: users[u],
        }))
        .sort((a, b) => b.points - a.points)

    return points
}

export default pointsByStandard
