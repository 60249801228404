import cn from 'classnames'
import * as React from 'react'

import css from './Form.module.scss'

interface IForm {
    className?: string
    onSubmit?: (e: React.FormEvent<HTMLFormElement>) => any
    panel?: boolean
}

export const Form: React.SFC<IForm> = ({
    className,
    children,
    onSubmit,
    panel,
}) => (
    <form
        className={cn(className, css.form, { [css.panel]: panel })}
        method="GET"
        onSubmit={onSubmit}
    >
        {children}
    </form>
)

Form.defaultProps = {
    panel: false,
}
