import cn from 'classnames'
import {
    createUserWithEmailAndPassword,
    FacebookAuthProvider,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInAnonymously,
    signInWithEmailAndPassword,
    signInWithRedirect,
    signOut,
    TwitterAuthProvider,
    User,
} from 'firebase/auth'
import {
    DatabaseReference,
    getDatabase,
    ref,
    onChildChanged,
    get,
    set,
} from 'firebase/database'
import * as React from 'react'
import { Link } from 'react-router-dom'

import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Icon } from 'components/Icon'
import { Tabs } from 'components/Tabs'
import { random } from 'utils'

import { IUser } from 'interfaces/IUser'
import AVATARS from '../../avatars'
import { IWithRouterProps, withRouter } from 'hoc/withRouter'

import css from './Auth.module.scss'

interface IProps {
    onAuth: (user: User | null) => any
}

interface IState {
    uid?: string
    avatar: string
    authing: boolean
    signup: boolean
    error?: string
    name: string
    user?: IUser
}

class Auth extends React.Component<IProps & IWithRouterProps, IState> {
    state: IState = {
        authing: true,
        signup: false,
        avatar: random(AVATARS),
        error: undefined,
        name: '',
    }

    email?: HTMLInputElement | null
    password?: HTMLInputElement | null
    passwordRepeat?: HTMLInputElement | null
    userRef?: DatabaseReference

    componentDidMount() {
        onAuthStateChanged(getAuth(), async (user) => {
            this.authenticated()

            if (user) {
                const { uid } = user

                this.userRef = ref(getDatabase(), `users/${uid}`)

                onChildChanged(this.userRef, (data) => {
                    this.setState((state) => ({
                        user: {
                            ...state.user,
                            [data.key as keyof IUser]: data.val(),
                        },
                    }))
                })

                get(this.userRef).then((data) => {
                    this.setState(
                        {
                            uid,
                            user: { ...this.state.user, ...data.val() },
                        },
                        () => this.props.onAuth(user),
                    )

                    if (!data.val() && this.userRef) {
                        const { avatar, name } = this.state

                        set(this.userRef, {
                            isAnonymous: user.isAnonymous ? true : null,
                            avatar,
                            name,
                        }).then(() => {
                            this.setState({ user: { avatar, name } })
                        })
                    }
                })
            } else {
                this.setState({ user: undefined }, this.props.onAuth(user))
            }
        })
    }

    handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const email = this.email && this.email.value
        const password = this.password && this.password.value

        if (!email || !password) {
            return
        }

        if (this.state.signup) {
            const passwordRepeat =
                this.passwordRepeat && this.passwordRepeat.value

            if (password === passwordRepeat) {
                this.register(email, password)
            } else {
                this.setState({
                    error: 'The passwords don’t match',
                })
            }
        } else {
            this.authenticate(email, password)
        }
    }

    handleGuest = (e: React.FormEvent) => {
        e.preventDefault()

        signInAnonymously(getAuth()).catch(
            (error) =>
                this.setState && this.setState({ error: error.toString() }),
        )
    }

    register = (email: string, password: string) => {
        this.authenticating()
        createUserWithEmailAndPassword(getAuth(), email, password)
            .then(this.authenticated)
            .catch((error) => {
                this.setState({ error: error.toString(), authing: false })
            })
    }

    authenticate = (email: string, password: string) => {
        this.authenticating()

        signInWithEmailAndPassword(getAuth(), email, password)
            .then(this.authenticated)
            .catch((error) => {
                this.setState({ error: error.toString(), authing: false })
            })
    }

    authenticating = () => this.setState({ authing: true })
    authenticated = () => this.setState({ authing: false })

    handleLogout = async () => {
        await signOut(getAuth())
        this.props.router.navigate('/')
    }

    handleTwitter = () => {
        signInWithRedirect(getAuth(), new TwitterAuthProvider())
    }

    handleFacebook = () => {
        signInWithRedirect(getAuth(), new FacebookAuthProvider())
    }

    handleGoogle = () => {
        signInWithRedirect(getAuth(), new GoogleAuthProvider())
    }

    toggleSignup = () => this.setState({ signup: !this.state.signup })

    handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ name: e.currentTarget.value })

    render() {
        const { error, signup, authing, name, avatar, user, uid } = this.state

        const className = cn(css.auth, {
            [css.authing]: !!authing,
            [css.unauthenticated]: !user,
            [css.authenticated]: !!user,
        })

        const displayName = (user && user.name) || ''

        return (
            <div className={className}>
                {user && (
                    <p className={css.user}>
                        {uid === '4n4s36iJbCMAvO59YQahxjOFH7z2' && (
                            <Link to="/admin">🥴</Link>
                        )}
                        <Link className={css.name} to="/profile">
                            {displayName || '...'}
                        </Link>
                        <button
                            type="button"
                            className={css.logout}
                            onClick={this.handleLogout}
                        >
                            &times;
                        </button>
                    </p>
                )}
                {!user && (
                    <Tabs panels={['Login', 'Guest']}>
                        <Form onSubmit={this.handleSubmit}>
                            <p>Signup with</p>
                            <ul className={css.providers}>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.handleFacebook}
                                    >
                                        <Icon icon="facebook" />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.handleGoogle}
                                    >
                                        <Icon icon="google" />
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        onClick={this.handleTwitter}
                                    >
                                        <Icon icon="twitter" />
                                    </button>
                                </li>
                            </ul>
                            <p>or</p>
                            {error && <p className={css.error}>{error}</p>}
                            <p>
                                <input
                                    type="email"
                                    ref={(node) => (this.email = node)}
                                    placeholder="Email"
                                />
                            </p>
                            <p>
                                <input
                                    type="password"
                                    ref={(node) => (this.password = node)}
                                    placeholder="Password"
                                />
                            </p>
                            {signup && (
                                <p>
                                    <input
                                        type="password"
                                        ref={(node) =>
                                            (this.passwordRepeat = node)
                                        }
                                        placeholder="Repeat password"
                                    />
                                </p>
                            )}
                            <p>
                                <Button type="submit">
                                    {signup ? 'Signup' : 'Login'}
                                </Button>
                            </p>
                            <p>
                                <button
                                    type="button"
                                    className={css.signup}
                                    onClick={this.toggleSignup}
                                >
                                    {signup ? 'Cancel' : 'Signup'}
                                </button>
                            </p>
                        </Form>
                        <Form onSubmit={this.handleGuest}>
                            {error && <p className={css.error}>{error}</p>}
                            {avatar && (
                                <p className={css.avatar}>
                                    <Avatar avatar={avatar} />
                                </p>
                            )}
                            <p>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder="Name"
                                    value={name}
                                    onChange={this.handleNameChange}
                                />
                            </p>
                            <p>
                                <Button
                                    disabled={name.length < 2}
                                    type="submit"
                                >
                                    Enter
                                </Button>
                            </p>
                        </Form>
                    </Tabs>
                )}
            </div>
        )
    }
}

export const AuthWithRouter = withRouter(Auth)
