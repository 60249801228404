import cn from 'classnames'
import * as React from 'react'

import { GameMode } from 'containers/GameMode'
import GameQuestions from 'containers/GameQuestions'

import { Button } from 'components/Button'
import Choices from 'components/Choices'
import GameFinished from 'components/GameFinished'
import { Loader } from 'components/Loader'
import QuickFireSongSelector from 'components/QuickFireSongSelector'
import Scoreboard, { IScoreboardProps } from 'components/Scoreboard'
import Stats from 'components/Stats'
import Track from 'components/Track'
import TrackPlayer from 'components/TrackPlayer'
import User from 'components/User'
import GAMEMODE from 'constants/gamemode'
import { IGame } from 'interfaces/IGame'
import { IRounds } from 'interfaces/IRounds'
import { ISelection } from 'interfaces/ISelection'
import { TransitionGroup } from 'react-transition-group'
import CSSTransition, {
    CSSTransitionProps,
} from 'react-transition-group/CSSTransition'

import css from './Game.module.scss'

function flatten<T>(obj?: { [key: string]: T }): Array<T & { key: string }> {
    if (!obj) return []
    return Object.keys(obj).map((o) => ({ key: o, ...obj[o] }))
}

const sortByTimestamp = (a: ISelection, b: ISelection) =>
    (a.timestamp || 0) - (b.timestamp || 0)

const GAMESTATES = {
    LOADING: 0,
    MODE: 10,
    QUESTIONS: 20,
    QUICK_FIRE_SELECT_SONG: 21,
    QUICK_FIRE_READY: 22,
    READY: 30,
    STARTED: 40,
    QUESTION: 50,
    ROUND_FINISHED: 60,
    FINISHED: 70,
}

const TRANSITIONS: { [key: string]: CSSTransitionProps } = {
    FADE_SCALE: {
        timeout: 500,
        classNames: {
            enter: css.fadeScaleEnter,
            enterActive: css.fadeScaleEnterActive,
            exit: css.fadeScaleLeave,
            exitActive: css.fadeScaleLeaveActive,
        },
    },
    SLIDE: {
        timeout: 500,
        classNames: {
            enter: css.slideEnter,
            enterActive: css.slideEnterActive,
            exit: css.slideLeave,
            exitActive: css.slideLeaveActive,
        },
    },
}

export interface IGameProps {
    game?: IGame
    rounds: IRounds
    countdown?: number
    showtrack?: boolean
    showchoices?: boolean
    scores?: IScoreboardProps['score']
    durations: {
        VOTING: number
        FADE: number
        STOP: number
    }
    uid?: string
    onQuestionsSubmit(): any
    onModeSubmit(): any
    onStartRound(): any
    onStartQuestion(): any
    onTrackFadingOut(): any
    onTrackFinished(): any
    onTrackStart(): any
    onTrackVotingClosed(): any
    roundNumber(): any
    onNextRound(): any
    onNewGame(): any
}

export class Game extends React.Component<IGameProps> {
    static defaultProps = {
        durations: {
            VOTING: 10000,
            FADE: 8000,
            STOP: 3000,
        },
        showtrack: false,
        showchoices: false,
        countdown: null,
        game: null,
        scores: null,
        genre: null,
        level: 'medium',
        rounds: {},
    }

    _getGameState = () => {
        const { game, rounds } = this.props

        if (!game) return GAMESTATES.LOADING

        if (!game.mode) return GAMESTATES.MODE

        const round = game.round && rounds[game.round]

        if (round && !round.questions) return GAMESTATES.QUESTIONS

        const roundKeys = game.rounds && Object.keys(game.rounds)

        if (game) {
            if (!game.mode) {
                return GAMESTATES.MODE
            } else {
                if (game.mode === GAMEMODE.QUICK_FIRE) {
                    if (!round || !round.questions) {
                        return GAMESTATES.QUICK_FIRE_SELECT_SONG
                    }

                    if (round && !round.started) {
                        return GAMESTATES.QUICK_FIRE_READY
                    }
                }

                if (round && !round.questions) {
                    return GAMESTATES.QUESTIONS
                } else {
                    if (!round) return GAMESTATES.LOADING

                    if (typeof round.question !== 'number') {
                        return GAMESTATES.LOADING
                    }

                    const lastRound =
                        game.round &&
                        roundKeys &&
                        roundKeys.indexOf(game.round) + 1 === roundKeys.length

                    const question =
                        round &&
                        round.questions &&
                        round.questions[round.question]

                    if (!round.started) {
                        return GAMESTATES.READY
                    } else if (round.finished && lastRound) {
                        return GAMESTATES.FINISHED
                    } else if (round.finished) {
                        return GAMESTATES.ROUND_FINISHED
                    } else if (question && question.started) {
                        return GAMESTATES.QUESTION
                    } else {
                        return GAMESTATES.STARTED
                    }
                }
            }
        } else {
            return GAMESTATES.LOADING
        }
    }

    _mainMenu = () => window.history.replaceState(null, '', '/')
    // _mainMenu = () => this.props.history && this.props.history.push("/");

    _players = () => {
        const { game } = this.props

        let players = 0

        if (game && game.players) {
            players = Object.keys(game.players).length
        }

        return (
            <span>
                <strong>{players}</strong>
                {` player${players === 1 ? '' : 's'}`}
            </span>
        )
    }

    get round() {
        const { game, rounds } = this.props

        if (!game) return null

        return game.round && rounds[game.round]
    }

    get question() {
        return (
            this.round &&
            this.round.questions &&
            typeof this.round.question === 'number' &&
            this.round.questions[this.round.question]
        )
    }

    render() {
        const {
            game,
            rounds,
            durations,
            countdown,
            showtrack,
            showchoices,
            scores,
        } = this.props

        if (!game) {
            return <Loader />
        }

        const correct =
            this.question &&
            this.question.correctAlternative >= 0 &&
            this.question.alternatives &&
            this.question.alternatives[this.question.correctAlternative]

        const GAMESTATE = this._getGameState()

        return (
            <div className={css.game}>
                <style
                    dangerouslySetInnerHTML={{
                        __html: '#rings { opacity: .25 }',
                    }}
                    key="css"
                />
                {GAMESTATE <= GAMESTATES.READY && [
                    <h2 className={css.code} key="code">
                        <small>Game #</small>
                        {game && game.code}
                    </h2>,
                    <span className={css.round} key="round">
                        {this.props.roundNumber() &&
                            `Round ${this.props.roundNumber()}`}
                    </span>,
                    <div className={css.amountOfPlayers} key="players">
                        {this._players()}
                    </div>,
                ]}
                {GAMESTATE === GAMESTATES.MODE && (
                    <GameMode
                        game={game}
                        onSubmit={this.props.onModeSubmit}
                        uid={this.props.uid}
                    />
                )}
                {GAMESTATE === GAMESTATES.QUESTIONS && (
                    <GameQuestions
                        game={game}
                        onSubmit={this.props.onQuestionsSubmit}
                    />
                )}
                {GAMESTATE === GAMESTATES.READY && (
                    <div>
                        <Button
                            center={true}
                            className={css.start}
                            color="green"
                            disabled={!game.players}
                            onClick={this.props.onStartRound}
                        >
                            Start round
                        </Button>
                        <Stats className={css.players} users={game.players} />
                    </div>
                )}
                {GAMESTATE === GAMESTATES.QUICK_FIRE_SELECT_SONG && (
                    <QuickFireSongSelector game={game} />
                )}
                {GAMESTATE === GAMESTATES.QUICK_FIRE_READY && (
                    <div>
                        <img
                            src={this.question ? this.question.imageUrl : ''}
                            style={{
                                display: 'table',
                                margin: '0 auto 20px',
                                maxWidth: '50vw',
                                maxHeight: '50vh',
                            }}
                            alt=""
                        />
                        <Button
                            center={true}
                            className={css.start}
                            color="green"
                            disabled={!game.players}
                            onClick={this.props.onStartRound}
                        >
                            Start question
                        </Button>
                    </div>
                )}
                <TransitionGroup>
                    {GAMESTATE === GAMESTATES.STARTED && (
                        <CSSTransition {...TRANSITIONS.SLIDE}>
                            <p className={css.countdownOverlay} key={countdown}>
                                {countdown}
                            </p>
                        </CSSTransition>
                    )}
                </TransitionGroup>
                {GAMESTATE === GAMESTATES.QUESTION &&
                    this.round &&
                    typeof this.round.question === 'number' &&
                    this.round.questions && (
                        <div>
                            <div className={css.question}>
                                Round {this.props.roundNumber()}
                                <br />
                                Track {this.round.question + 1} of{' '}
                                {this.round.questions.length}
                                <br />
                                <code>{game.code}</code>
                            </div>
                            <TrackPlayer
                                durations={{
                                    voting: durations.VOTING,
                                    fade: durations.FADE,
                                    stop: durations.STOP,
                                }}
                                onStart={this.props.onTrackStart}
                                track={
                                    (this.question &&
                                        this.question.previewUrl) ||
                                    ''
                                }
                            />
                            {!scores && (
                                <div
                                    className={css.amountOfPlayers}
                                    key="players"
                                >
                                    <strong>
                                        {this.question &&
                                        this.question.selections
                                            ? Object.keys(
                                                  this.question.selections,
                                              ).length
                                            : '0'}
                                    </strong>{' '}
                                    /{' '}
                                    <strong>
                                        {
                                            Object.keys(
                                                (game && game.players) || [],
                                            ).length
                                        }
                                    </strong>
                                    <p>Answers</p>
                                </div>
                            )}

                            <div>
                                {showchoices && !scores && this.question && (
                                    <div
                                        className={css.fastest}
                                        data-element="fastest-players"
                                    >
                                        <p>Fastest players</p>
                                        <ul>
                                            {flatten(this.question.selections)
                                                .sort(sortByTimestamp)
                                                .splice(0, 3)
                                                .map((s) => (
                                                    <li key={s.key}>
                                                        <User
                                                            id={s.key}
                                                            size="small"
                                                        />
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                                <TransitionGroup>
                                    {(countdown || 0) > 0 && (
                                        <CSSTransition {...TRANSITIONS.SLIDE}>
                                            <p
                                                className={css.countdownOverlay}
                                                data-element="countdown"
                                                key={countdown}
                                            >
                                                {countdown}
                                            </p>
                                        </CSSTransition>
                                    )}
                                    {this.question &&
                                        this.question.started &&
                                        showchoices && (
                                            <CSSTransition
                                                {...TRANSITIONS.FADE_SCALE}
                                            >
                                                <>
                                                    <Choices
                                                        choices={
                                                            this.question
                                                                .alternatives
                                                        }
                                                        duration={
                                                            durations.VOTING
                                                        }
                                                        key="choices"
                                                        onComplete={
                                                            this.props
                                                                .onTrackVotingClosed
                                                        }
                                                    />
                                                    <style
                                                        dangerouslySetInnerHTML={{
                                                            __html: '#rings { opacity: 0 }',
                                                        }}
                                                        key="css"
                                                    />
                                                </>
                                            </CSSTransition>
                                        )}
                                    {showtrack && !scores && (
                                        <CSSTransition
                                            {...TRANSITIONS.FADE_SCALE}
                                        >
                                            <div
                                                style={{
                                                    position: 'fixed',
                                                    top: 0,
                                                    left: 0,
                                                    right: 0,
                                                    bottom: 0,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                {correct && this.question && (
                                                    <Track
                                                        artists={
                                                            correct.artists
                                                        }
                                                        image={
                                                            this.question
                                                                .imageUrl
                                                        }
                                                        name={correct.name}
                                                    />
                                                )}
                                            </div>
                                        </CSSTransition>
                                    )}
                                    {scores && (
                                        <CSSTransition
                                            {...TRANSITIONS.FADE_SCALE}
                                        >
                                            <div className={css.scores}>
                                                <p>Scoreboard</p>
                                                <ul className={css.indicators}>
                                                    <li
                                                        className={cn({
                                                            [css.activeIndicator]:
                                                                scores ===
                                                                'question',
                                                        })}
                                                    >
                                                        Question
                                                    </li>
                                                    <li
                                                        className={cn({
                                                            [css.activeIndicator]:
                                                                scores ===
                                                                'total',
                                                        })}
                                                    >
                                                        Total
                                                    </li>
                                                </ul>
                                                <Scoreboard
                                                    game={game}
                                                    rounds={rounds}
                                                    score={scores}
                                                />
                                            </div>
                                        </CSSTransition>
                                    )}
                                </TransitionGroup>
                            </div>
                        </div>
                    )}
                {GAMESTATE === GAMESTATES.ROUND_FINISHED && [
                    <Button key="next-round" onClick={this.props.onNextRound}>
                        Next round
                    </Button>,
                    <Scoreboard
                        game={game}
                        key="score"
                        rounds={rounds}
                        score="total"
                    />,
                ]}
                {GAMESTATE === GAMESTATES.FINISHED && (
                    <GameFinished
                        mainMenu={this._mainMenu}
                        game={game}
                        rounds={rounds}
                        newGame={this.props.onNewGame}
                    />
                )}
            </div>
        )
    }
}
