import * as React from 'react'

import css from './Swiper.module.scss'

class Swiper extends React.Component<{
    onSwipe(choice: number): any
}> {
    xDown?: number
    yDown?: number

    componentDidMount() {
        // TODO: Disable if touch device?
        document.addEventListener('keydown', this._handleKeyPress)
    }

    componentWillUnmount() {
        const html = document.querySelector('html')
        const body = document.querySelector('body')

        if (html) {
            html.style.overflow = 'hidden'
        }

        if (body) {
            body.style.overflow = 'hidden'
            body.style.position = 'relative'
        }

        document.removeEventListener('keydown', this._handleKeyPress)
    }

    _handleKeyPress = (e: KeyboardEvent) => {
        const keysToChoice: { [key: number]: number } = {
            38: 0,
            40: 3,
            39: 2,
            37: 1,
        }

        const choice: number = keysToChoice[e.keyCode]

        if (choice !== undefined) {
            this.props.onSwipe(choice)
        }
    }

    triggerSwipe = (choice: number) => this.props.onSwipe(choice)

    _handleClick = (e: React.MouseEvent<HTMLSpanElement>) =>
        e.currentTarget.dataset.choice &&
        this.props.onSwipe(parseInt(e.currentTarget.dataset.choice))

    handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        this.xDown = e.touches[0].clientX
        this.yDown = e.touches[0].clientY
    }

    handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!this.xDown || !this.yDown) {
            return
        }

        const xUp = e.touches[0].clientX
        const yUp = e.touches[0].clientY

        const xDiff = this.xDown - xUp
        const yDiff = this.yDown - yUp

        if (Math.abs(xDiff) > Math.abs(yDiff)) {
            /*most significant*/
            if (xDiff > 0) {
                /* left swipe */
                this.triggerSwipe(1)
            } else {
                /* right swipe */
                this.triggerSwipe(2)
            }
        } else {
            if (yDiff > 0) {
                /* up swipe */
                this.triggerSwipe(0)
            } else {
                /* down swipe */
                this.triggerSwipe(3)
            }
        }

        /* reset values */
        this.xDown = undefined
        this.yDown = undefined
    }

    render() {
        return (
            <div
                className={css.swiper}
                onTouchMove={this.handleTouchMove}
                onTouchStart={this.handleTouchStart}
            >
                <div className={css.arrows}>
                    <span data-choice="0" onClick={this._handleClick}>
                        ↑
                    </span>
                    <span data-choice="1" onClick={this._handleClick}>
                        ←
                    </span>
                    <span data-choice="2" onClick={this._handleClick}>
                        →
                    </span>
                    <span data-choice="3" onClick={this._handleClick}>
                        ↓
                    </span>
                </div>
            </div>
        )
    }
}

export default Swiper
