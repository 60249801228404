import { Game as GameComponent } from 'components/Game'
import { GameEngine } from 'hoc/GameEngine'
import { withRouter } from 'hoc/withRouter'

const WrappedGame = GameEngine(GameComponent)

export function Game() {
    const Component = withRouter(WrappedGame)
    return <Component />
}

// export const Game: React.SFC<RouteComponentProps<{ id: string }>> = ({
//   match,
//   history,
// }) => <WrappedGame id={match.params.id} history={history} />;
