export const user = {
    get(uid: string) {
        if (window.sessionStorage) {
            const data = window.sessionStorage.getItem(`user/${uid}`)
            return data ? JSON.parse(data) : undefined
        }
    },
    set(uid: string, data: {}) {
        if (window.sessionStorage) {
            window.sessionStorage.setItem(`user/${uid}`, JSON.stringify(data))
        }
    },
    clear(uid: string) {
        if (window.sessionStorage) {
            window.sessionStorage.removeItem(`user/${uid}`)
        }
    },
}
