import cn from 'classnames'
import * as React from 'react'

import css from './Ring.module.scss'

const Ring: React.SFC<{ className?: string; color: string; text: string }> = ({
    className,
    color,
    text,
}) => (
    <div className={cn(css.ring, css[color], className)}>
        <span>{text}</span>
    </div>
)

export default Ring
