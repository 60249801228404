import cn from 'classnames'
import * as React from 'react'

import css from './Tabs.module.scss'

interface ITabsProps {
    panels: string[]
    style?: React.CSSProperties
}

interface ITabsState {
    tab: number
}

export class Tabs extends React.Component<ITabsProps, ITabsState> {
    state = {
        tab: 0,
    }

    setTab = (e: React.MouseEvent<HTMLElement>) =>
        e.currentTarget.dataset.tab &&
        this.setState({ tab: parseInt(e.currentTarget.dataset.tab) })

    render() {
        const { panels, children, style } = this.props
        const { tab } = this.state

        return (
            <div className={css.tabs} style={style}>
                <ul className={css.panels}>
                    {panels.map((p, i) => (
                        <li
                            className={cn(css.panel, {
                                [css.active]: tab === i,
                            })}
                            key={p}
                        >
                            <button
                                type="button"
                                data-tab={i}
                                onClick={this.setTab}
                            >
                                {p}
                            </button>
                        </li>
                    ))}
                </ul>
                {Array.isArray(children)
                    ? children.map((t, i) => (
                          <div
                              className={cn(css.tab, {
                                  [css.active]: tab === i,
                              })}
                              key={i}
                          >
                              {t}
                          </div>
                      ))
                    : children}
            </div>
        )
    }
}
