import cn from 'classnames'
import * as React from 'react'

import css from './Panel.module.scss'

export const Panel: React.SFC<{ className?: string }> = ({
    className,
    children,
}) => <div className={cn(className, css.panel)}>{children}</div>

export default Panel
