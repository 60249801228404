import * as React from 'react'

import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Tabs } from 'components/Tabs'
import { get, getDatabase, ref } from 'firebase/database'
import { getAuth } from 'firebase/auth'

interface IState {
    playlists?: {
        [pid: string]: string
    }
}

class Admin extends React.Component {
    playlistUrl: HTMLInputElement | null = null
    playlistTitle: HTMLInputElement | null = null

    state: IState = {}

    componentDidMount() {
        this.fetchPlaylists()
    }

    fetchPlaylists = async () => {
        const snapshot = await get(ref(getDatabase(), '/playlists'))

        if (snapshot && snapshot.val()) {
            this.setState({ playlists: snapshot.val() })
        }
    }

    addPlaylist = (e: React.FormEvent) => {
        e.preventDefault()

        const { playlistUrl, playlistTitle } = this

        if (playlistUrl && playlistTitle) {
            const match = playlistUrl.value.match(/playlist\/(.*?)\?/)

            if (!match) {
                return alert('invalid spotify playlist url')
            }

            // const [, pid] = match

            // TODO
            // firebase
            //   .database()
            //   .ref("/playlists")
            //   .child(pid)
            //   .set(playlistTitle.value, () => {
            //     playlistTitle.value = "";
            //     playlistUrl.value = "";
            //     this.fetchPlaylists();
            //   });
        }
    }

    deletePlaylist = (pid: string) => {
        // TODO
        // confirm("Are you fucking sure??") &&
        //   firebase
        //     .database()
        //     .ref("/playlists")
        //     .child(pid)
        //     .set(null, this.fetchPlaylists);
    }

    render() {
        const { state } = this

        const user = getAuth().currentUser

        if (!user || user.uid !== '4n4s36iJbCMAvO59YQahxjOFH7z2') {
            return <div style={{ textAlign: 'center', fontSize: 100 }}>🥴</div>
        }

        return (
            <Tabs
                style={{ maxWidth: 600, margin: 'auto', textAlign: 'center' }}
                panels={['Playlists']}
            >
                <Form onSubmit={this.addPlaylist}>
                    <p>
                        <input
                            ref={(node) => (this.playlistUrl = node)}
                            type="text"
                            defaultValue=""
                            placeholder="https://open.spotify.com/playlist/1nx3SZFmIS50tC08QD4pna?si=2GS1rT8IToynNXILcyPmMg"
                        />
                    </p>
                    <p>
                        <input
                            ref={(node) => (this.playlistTitle = node)}
                            type="text"
                            defaultValue=""
                            placeholder="Title"
                        />
                    </p>
                    <p>
                        <Button type="submit">Add</Button>
                    </p>
                    <hr />
                    <em style={{ color: 'red', fontWeight: 900 }}>
                        Click to delete (no regrets, yolo?)
                    </em>
                    {state.playlists && (
                        <ul
                            style={{
                                textAlign: 'left',
                                listStyle: 'none',
                                fontWeight: 700,
                            }}
                        >
                            {Object.keys(state.playlists).map(
                                (k) =>
                                    state.playlists && (
                                        <li
                                            key={k}
                                            style={{ margin: '15px 0' }}
                                        >
                                            <button
                                                type="button"
                                                onClick={() =>
                                                    this.deletePlaylist(k)
                                                }
                                            >
                                                {state.playlists[k]}
                                            </button>
                                        </li>
                                    ),
                            )}
                        </ul>
                    )}
                </Form>
            </Tabs>
        )
    }
}

export default Admin
