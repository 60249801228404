import * as React from 'react'

import Bars from 'components/Bars'
import { Button } from 'components/Button'
import { Loader } from 'components/Loader'
import MiniScoreboard from 'components/MiniScoreboard'
import Modal from 'components/Modal'
import Swiper from 'components/Swiper'
import TVInput from 'components/TV'
import GAMEMODE from 'constants/gamemode'
import { VOTING_STATES } from 'hoc/PlayEngine'
import { IGame } from 'interfaces/IGame'
import { IQuestion } from 'interfaces/IQuestion'
import { QR } from 'components/QR'

import css from './Play.module.scss'
import { joinGameByCode } from 'utils/firebase'

export interface IPlayProps {
    state: VOTING_STATES
    game?: IGame
    question?: number
    started?: number | boolean
    finished?: number
    uid: string
    questions: IQuestion[]
    onStartGame?: () => any
    onChoice(choice: number | string): any
    onLeave(): any
    onJoinGame(): any
}

export class Play extends React.Component<IPlayProps> {
    static defaultProps: Partial<IPlayProps> = {
        state: VOTING_STATES.VOTE,
    }

    _handleChoice = (choice: number) => this.props.onChoice(choice)

    _handleSwipe = (choice: number) => this._handleChoice(choice)

    _getAlternatives = () => {
        const { props } = this

        if (props.state !== VOTING_STATES.VOTE) {
            return undefined
        }

        if (typeof props.question === 'undefined') {
            return undefined
        }

        if (!props.questions || !props.questions[props.question]) {
            return undefined
        }

        return props.questions[props.question].alternatives
    }

    _handleJoin = async () => {
        if (!this.props.game?.code) return

        await joinGameByCode(this.props.game.code)
    }

    get song() {
        const { props } = this

        if (!props.questions) {
            return null
        }

        if (typeof props.question === 'undefined') {
            return null
        }

        if (!props.questions[props.question]) {
            return null
        }

        const question = props.questions[props.question]
        const correct =
            question.alternatives &&
            question.alternatives[question.correctAlternative]

        return {
            imageUrl: question.imageUrl,
            artists: correct && correct.artists,
            name: correct && correct.name,
        }
    }

    render() {
        const { game, started, finished, state, uid, onStartGame } = this.props

        const { song } = this

        if (!game) {
            return <Loader text="Loading game..." />
        }

        const joined = game.players && game.players[uid]

        if (!joined) {
            return (
                <div style={{ padding: 20, textAlign: 'center' }}>
                    <h2 className={css.join}>
                        <small>Game #</small>
                        {game.code}
                    </h2>
                    <br />
                    <Button onClick={this._handleJoin}>Join</Button>
                </div>
            )
        }

        const TV = game.TV === true

        const isAdmin = game.admin && game.admin === this.props.uid

        return (
            <div className={css.play}>
                <style
                    dangerouslySetInnerHTML={{
                        __html: '#rings { display: none; }',
                    }}
                />
                <div className={css.code}>
                    <code>{game.code}</code>
                </div>
                {finished && (
                    <div>
                        <p className={css.finished}>
                            <strong>Round finished</strong>
                        </p>
                        {game.mode === GAMEMODE.QUICK_FIRE && (
                            <div>
                                {song && (
                                    <div className={css.song}>
                                        <img
                                            alt=""
                                            className={css.cover}
                                            src={song.imageUrl}
                                        />
                                        <div>
                                            <p className={css.name}>
                                                {song.name}
                                            </p>
                                            {song.artists && (
                                                <p className={css.artists}>
                                                    {song.artists.join(', ')}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                                <p>WIP __ Scoreboard goes here</p>
                                <MiniScoreboard game={game} />
                            </div>
                        )}
                        <p className={css.leave}>
                            <Button onClick={this.props.onLeave} size="small">
                                Leave game
                            </Button>
                        </p>
                    </div>
                )}
                {finished && game.newGame && (
                    <Modal>
                        <h2>There is a new game!</h2>
                        <p>
                            <Button
                                color="green"
                                onClick={this.props.onJoinGame}
                            >
                                Join
                            </Button>
                        </p>
                        <p>
                            <Button
                                size="small"
                                color="red"
                                onClick={this.props.onLeave}
                            >
                                Leave
                            </Button>
                        </p>
                    </Modal>
                )}
                {!finished && !started && (
                    <div>
                        <Bars
                            className={css.bars}
                            text="Waiting for round to start!"
                        />
                        <QR />
                        {onStartGame && isAdmin && (
                            <Button color="green" onClick={onStartGame}>
                                Start game
                            </Button>
                        )}
                        <Button
                            className={css.leave}
                            onClick={this.props.onLeave}
                            size="small"
                        >
                            Leave game
                        </Button>
                    </div>
                )}
                {!finished && started && (
                    <div>
                        {state === VOTING_STATES.VOTE &&
                            (TV ? (
                                <TVInput
                                    alternatives={this._getAlternatives()}
                                    onChoice={this.props.onChoice}
                                />
                            ) : (
                                <Swiper onSwipe={this._handleSwipe} />
                            ))}
                        {state === VOTING_STATES.VOTING && (
                            <Loader text="Submitting vote..." />
                        )}
                        {state === VOTING_STATES.VOTED && (
                            <div className={css.voted}>Answer registered</div>
                        )}
                    </div>
                )}
            </div>
        )
    }
}

export default Play
