import React, { useEffect, useState } from 'react'
import QRCode from 'qrcode'

import { Button } from 'components/Button'

import css from './QR.module.scss'

export function QR() {
    let el: HTMLDivElement | null = null

    const [popup, setPopup] = useState(false)

    useEffect(() => {
        try {
            QRCode.toCanvas(window.location.href, (err, canvas) => {
                if (err) throw err

                canvas.classList.add(css.canvas)

                el?.appendChild(canvas)
            })
        } catch (err) {}
    }, [el])

    return (
        <>
            <Button className={css.qr} onClick={() => setPopup(true)}>
                QR
            </Button>
            <div
                style={{ display: popup ? undefined : 'none' }}
                className={css.popup}
                ref={(node) => (el = node)}
            >
                <Button
                    className={css.close}
                    color="green"
                    onClick={() => setPopup(false)}
                    size="small"
                >
                    X
                </Button>
            </div>
        </>
    )
}
