import React from 'react'

import GAMEMODE from 'constants/gamemode'
import { IGame } from 'interfaces/IGame'
import { IGenre } from 'interfaces/IGenre'
import { IRound } from 'interfaces/IRound'
import { IRounds } from 'interfaces/IRounds'
import { user } from 'utils/cache'
import { saveGame } from 'utils/firebase'
import {
    child,
    DatabaseReference,
    DataSnapshot,
    get,
    getDatabase,
    off,
    onChildRemoved,
    onValue,
    push,
    ref,
    serverTimestamp,
    set,
    update,
} from 'firebase/database'
import { getAuth } from 'firebase/auth'
import { IWithRouterProps } from 'hoc/withRouter'

const DURATIONS = {
    VOTING: 10000,
    FADE: 8000,
    STOP: 3000,
}

interface IGameEngineState {
    showtrack: boolean
    showchoices: boolean
    countdown?: number
    game?: IGame
    genres?: { [key: string]: IGenre }
    rounds?: IRounds
    scores?: string
    uid?: string
}

export const GameEngine = (ComposedComponent: typeof React.Component) =>
    class extends React.Component<IWithRouterProps, IGameEngineState> {
        static displayName = 'GameEngine'

        state: IGameEngineState = {
            showtrack: false,
            showchoices: false,
        }
        game?: DatabaseReference
        timers: number[]
        rounds: { [key: string]: DatabaseReference }

        constructor(props: IWithRouterProps) {
            super(props)

            this.rounds = {}
            this.timers = []

            const { currentUser } = getAuth()

            if (currentUser) {
                this.state.uid = currentUser.uid
            }
        }

        componentDidMount() {
            const { id } = this.props.router.params
            this.game = ref(getDatabase(), `games/${id}`)

            this.addListeners()

            this.timers = []

            get(ref(getDatabase(), 'genres')).then((data) => {
                this.setState({
                    genres: data.val() as IGameEngineState['genres'],
                })
            })
        }

        componentDidUpdate(prevProps: IWithRouterProps) {
            if (prevProps.router.params.id !== this.props.router.params.id) {
                this.handleNewGame(this.props.router.params.id)
            }
        }

        commponentWillUnmount() {
            this.removeListeners()
        }

        addListeners() {
            if (!this.game) return

            // TODO
            // this.listeners.push(onValue(this.game, this._updateGame))
            onValue(this.game, this._updateGame)
            onValue(child(this.game, 'rounds'), this._updateRounds)
            onChildRemoved(child(this.game, 'players'), this.handlePlayerLeave)
        }

        removeListeners() {
            if (!this.game) return

            off(this.game)
            off(child(this.game, 'rounds'))
            off(child(this.game, 'players'))
        }

        newGame = () =>
            saveGame().then((res: { game: IGame; id: string }) => {
                if (!this.game) return
                update(this.game, {
                    newGame: res.id,
                }).then(() => {
                    this.props.router.navigate(`/game/${res.id}`)
                })
            })

        handleNewGame(id?: string) {
            if (!id) return
            this.removeListeners()
            this.setState({ rounds: undefined })
            this.game = ref(getDatabase(), `games/${id}`)
            this.addListeners()
        }

        _updateGame = (data: DataSnapshot) => {
            const game = data.val() as IGame

            if (game) {
                this.setState({ game })
            }
        }

        _updateRounds = (data: DataSnapshot) => {
            if (!data.val()) return

            if (!this.rounds) {
                this.rounds = {}
            }

            Object.keys(data.val()).forEach((round) => {
                if (!this.rounds[round]) {
                    const reference = ref(getDatabase(), `rounds/${round}`)
                    this.rounds[round] = reference

                    onValue(reference, this._updateRound)
                }
            })
        }

        _updateRound = (data: DataSnapshot) => {
            if (this.state.game && this.state.game.round === data.key) {
                const round = this.state.rounds && this.state.rounds[data.key]
                if (round && !round.started) {
                    const val = data.val()

                    if (val.started) {
                        this._startCountdown(3)
                    }
                }
            }

            this.setState((state) => ({
                rounds: {
                    ...state.rounds,
                    [data.key as string]: data.val() as IRound,
                },
            }))
        }

        _handleModeSubmit = ({
            mode,
            TV,
            rounds,
            questions,
        }: {
            mode: IGame['mode']
            TV: boolean
            rounds: number
            questions: number
        }) => {
            const { id } = this.props.router.params

            const updates: { [path: string]: any } = {
                [`games/${id}/mode`]: mode,
                [`games/${id}/questions`]: questions,
                [`games/${id}/rounds`]: {},
            }

            if (mode === GAMEMODE.QUICK_FIRE) {
                updates[`games/${id}/TV`] = TV
            }

            for (let i = 1; i <= rounds; i++) {
                const roundKey = push(ref(getDatabase(), 'rounds')).key
                if (i === 1) updates[`games/${id}/round`] = roundKey
                updates[`games/${id}/rounds`][roundKey as string] = true
                updates[`rounds/${roundKey}`] = {
                    started: false,
                }
            }

            update(ref(getDatabase()), updates).then(() => {
                if (mode === GAMEMODE.QUICK_FIRE && !TV) {
                    this.props.router.navigate(`/quickfire/${id}`)
                }
            })
        }

        _handleQuestionsSubmit = (response: {
            questions: IRound['questions']
        }) => {
            const { game } = this.state
            const { questions } = response

            if (!response.questions || !game) {
                throw Error('No questions in response')
            }

            update(ref(getDatabase(), `rounds/${game.round}`), {
                question: 0,
                questions,
            })

            // Preload images
            this._preloadAssets(questions)
        }

        _preloadAssets = (questions: IRound['questions']) => {
            questions &&
                questions.forEach((q) => {
                    if (q.imageUrl) {
                        const img = new Image()
                        img.src = q.imageUrl
                    }

                    if (q.previewUrl) {
                        const song = new Audio(q.previewUrl)
                        song.oncanplaythrough = () => {
                            song.volume = 0
                            song.play()
                        }
                    }
                })
        }

        _handleStartRound = () => {
            const { game } = this.state

            const { currentUser } = getAuth()

            if (
                !game ||
                !game.admin ||
                !currentUser ||
                game.admin !== currentUser.uid
            ) {
                return
            }

            set(ref(getDatabase(), `rounds/${game.round}/started`), true)
        }

        _handleStartQuestion = () => this._startCountdown(5)

        _startCountdown = (countdown = 10) => {
            this.setState(
                {
                    countdown,
                },
                () => {
                    const interval = window.setInterval(() => {
                        const newCountdown =
                            (this.state.countdown as number) - 1
                        this.setState(
                            {
                                countdown:
                                    newCountdown > 0 ? newCountdown : undefined,
                            },
                            () => {
                                if (!this.state.countdown) {
                                    window.clearInterval(interval)
                                    this._startQuestion()
                                }
                            },
                        )
                    }, 1000)
                },
            )
        }

        _startQuestion = () => {
            const { game, rounds } = this.state

            if (!game || !game.round || !rounds) return

            const question = rounds[game.round].question

            update(
                ref(
                    getDatabase(),
                    `rounds/${game.round}/questions/${question}`,
                ),
                {
                    started: serverTimestamp(),
                },
            )
        }

        _onTrackFadingOut = () => {
            const { game, rounds } = this.state

            if (!game || !game.round || !rounds) return

            const round = rounds[game.round]

            if (
                !round ||
                typeof round.question !== 'number' ||
                !round.questions
            ) {
                return
            }

            if (round.question + 1 === round.questions.length) {
                window.setTimeout(() => {
                    update(ref(getDatabase(), `rounds/${game.round}`), {
                        finished: serverTimestamp(),
                    })

                    const roundNumber = this._roundNumber()

                    if (
                        this.game &&
                        game &&
                        game.rounds &&
                        roundNumber === Object.keys(game.rounds).length
                    ) {
                        update(this.game, {
                            finished: serverTimestamp(),
                        })
                    }
                }, 3000)
            } else {
                this._startCountdown(3)
            }
        }

        _onTrackFinished = () => {
            const { game } = this.state

            if (
                !game ||
                !this.round ||
                typeof this.round.question === 'undefined' ||
                !this.round.questions
            ) {
                return
            }

            this.setState({
                scores: undefined,
                countdown: undefined,
                showchoices: false,
                showtrack: false,
            })

            if (this.round.question + 1 !== this.round.questions.length) {
                set(
                    ref(getDatabase(), `rounds/${game.round}/question`),
                    this.round.question + 1,
                )
            }
        }

        _onTrackVotingClosed = () => {
            console.info('_onVotingClosed')
            this.setState({
                showchoices: false,
                showtrack: true,
            })

            window.setTimeout(() => {
                console.info('scores: question')
                this.setState({ scores: 'question' })
            }, 3000)
            window.setTimeout(() => {
                console.info('scores: total')
                this.setState({ scores: 'total' })
            }, 6000)
        }

        _onTrackStart = () => {
            this.setState({ showchoices: true }, () => {
                this.timers.push(
                    window.setTimeout(() => {
                        console.info('DURATIONS.FADE')
                        this._onTrackFadingOut()
                    }, DURATIONS.VOTING + DURATIONS.FADE),
                )

                this.timers.push(
                    window.setTimeout(() => {
                        console.info('DURATIONS.STOP')
                        this._onTrackFinished()
                    }, DURATIONS.VOTING + DURATIONS.FADE + DURATIONS.STOP),
                )
            })
        }

        _roundNumber = (round: string | null = null) => {
            const { game } = this.state

            if (!game || !game.round || !game.rounds) return

            if (round) {
                return Object.keys(game.rounds).indexOf(round) + 1
            }

            return Object.keys(game.rounds).indexOf(game.round) + 1
        }

        _handleNextRound = () => {
            const { game } = this.state
            const round = this._roundNumber()

            if (!this.game || !game || !game.rounds || !round) return

            update(this.game, {
                round: Object.keys(game.rounds)[round],
            })
        }

        handlePlayerLeave = (data: DataSnapshot) =>
            data.key && user.clear(data.key)

        get round() {
            const { game, rounds } = this.state

            if (!game || !game.round || !rounds) return undefined

            return rounds[game.round]
        }

        render() {
            return (
                <ComposedComponent
                    {...this.props}
                    {...this.state}
                    durations={DURATIONS}
                    onModeSubmit={this._handleModeSubmit}
                    onNextRound={this._handleNextRound}
                    onQuestionsSubmit={this._handleQuestionsSubmit}
                    onStartQuestion={this._handleStartQuestion}
                    onStartRound={this._handleStartRound}
                    onTrackFadingOut={this._onTrackFadingOut}
                    onTrackFinished={this._onTrackFinished}
                    onTrackStart={this._onTrackStart}
                    onTrackVotingClosed={this._onTrackVotingClosed}
                    roundNumber={this._roundNumber}
                    onNewGame={this.newGame}
                />
            )
        }
    }
