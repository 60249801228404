import cn from 'classnames'
import * as React from 'react'

import css from './RadioGroup.module.scss'

export interface IRadioGroupProps {
    name: string
    title?: string
    value?: string
    values: {
        [key: string]: string
    }
    vertical?: boolean
    onChange(e: React.ChangeEvent<HTMLInputElement>): any
}

export const RadioGroup: React.SFC<IRadioGroupProps> = ({
    name,
    onChange,
    title,
    value,
    values,
    vertical,
}) => (
    <div className={css.radiogroup}>
        {title && <p className={css.title}>{title}</p>}
        <div
            className={cn(css.values, {
                [css.vertical]: vertical,
            })}
        >
            {Object.keys(values).map((v) => (
                <label className={css.value} key={v}>
                    <input
                        checked={v === value}
                        name={name}
                        onChange={onChange}
                        type="radio"
                        value={v}
                    />
                    <span>{values[v]}</span>
                </label>
            ))}
        </div>
    </div>
)

export default RadioGroup
