import cn from 'classnames'
import * as React from 'react'

import {
    ApiGenresResult,
    getGenresRated,
    getTracksForGenres,
    getTracksForPlaylist,
    IApiGenreResult,
} from 'api'
import { Button } from 'components/Button'
import { Form } from 'components/Form'
import { Input } from 'components/Input'
import { Loader } from 'components/Loader'
import Modal from 'components/Modal'
import PlaylistPicker from 'components/PlaylistPicker'
import RadioGroup from 'components/RadioGroup'

import GAMEMODE from 'constants/gamemode'
import { IGame } from 'interfaces/IGame'

import css from './GameQuestions.module.scss'

enum LEVELS {
    Easy,
    Medium,
    Hard,
}

interface IGameQuestionsProps {
    game?: IGame
    onSubmit?: () => any
}

interface IGameQuestionsState {
    genre: string
    genrePage: number
    level: LEVELS
    loading: boolean
    genres: ApiGenresResult
    playlist: {
        uid: string
        pid: string
    }
    error?: any
    showAllGenres: boolean
    genreFilter: string
}

class GameQuestions extends React.Component<
    IGameQuestionsProps,
    IGameQuestionsState
> {
    state: IGameQuestionsState = {
        genre: '',
        genrePage: 1,
        level: LEVELS.Easy,
        loading: false,
        genres: [],
        playlist: {
            uid: '',
            pid: '',
        },
        showAllGenres: false,
        genreFilter: '',
    }

    componentDidMount() {
        const { props } = this

        if (props.game && props.game.mode === GAMEMODE.GENRE) {
            getGenresRated().then(
                (genres) => this.setState && this.setState({ genres }),
            )
        }
    }

    _handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.currentTarget

        switch (name) {
            case 'level':
                this.setState({ level: parseInt(value) as LEVELS })
                break

            default:
                throw new Error(`Unhandled change event for name: ${name}`)
        }
    }

    _handleOnSubmit = (e: React.FormEvent) => {
        e.preventDefault()

        const { game, onSubmit } = this.props
        const { genre, level, playlist } = this.state

        if (!game) {
            throw new Error('Game is undefined')
        }

        this.setState(
            {
                loading: true,
            },
            () => {
                if (!game.questions) {
                    throw new Error('Game is missing questions')
                }

                switch (game.mode) {
                    case GAMEMODE.GENRE:
                        getTracksForGenres({ genre, level }, game.questions)
                            .then(onSubmit)
                            .catch((error) =>
                                this.setState({ error, loading: false }),
                            )
                        break

                    case GAMEMODE.PLAYLIST:
                        getTracksForPlaylist({ ...playlist }, game.questions)
                            .then(onSubmit)
                            .catch((error) =>
                                this.setState({ error, loading: false }),
                            )
                        break
                }
            },
        )
    }

    _setGenre = (e: React.MouseEvent<HTMLElement>) =>
        this.setState({
            genre: e.currentTarget.dataset.genre as string,
            showAllGenres: false,
        })

    _setPlaylist = ({ uid, pid }: { uid: string; pid: string }) =>
        this.setState({ playlist: { uid, pid } })

    handleGenreFilter = (e: React.ChangeEvent<HTMLInputElement>) =>
        this.setState({ genreFilter: e.currentTarget.value })

    filterGenres = (genre: IApiGenreResult) =>
        this.state.genreFilter
            ? genre.name.includes(this.state.genreFilter)
            : true

    get selectedGenre() {
        const { genre } = this.state

        if (!genre) {
            return undefined
        }

        const index = this.state.genres.findIndex((g) => g.id === genre)

        if (index < 10) return undefined

        return this.state.genres[index].name
    }

    render() {
        const {
            error,
            genre,
            genres,
            level,
            loading,
            playlist,
            showAllGenres,
            genreFilter,
        } = this.state
        const { game } = this.props

        if (!game) {
            return <Loader />
        }

        return (
            <Form onSubmit={this._handleOnSubmit} panel={true}>
                {game.mode === GAMEMODE.GENRE && (
                    <>
                        <ul
                            className={cn(css.genres, {
                                [css.genresLoading]: genres.length === 0,
                            })}
                        >
                            {genres.length === 0 && (
                                <Loader
                                    overlay={false}
                                    text="Loading genres..."
                                />
                            )}
                            {genres.slice(0, 10).map((g) => (
                                <li className={css.genre} key={g.name}>
                                    <button
                                        type="button"
                                        className={cn({
                                            [css.activeGenre]: genre === g.id,
                                        })}
                                        data-genre={g.id}
                                        onClick={this._setGenre}
                                    >
                                        {g.name}
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {this.selectedGenre && (
                            <p className={css.selectedGenre}>
                                {this.selectedGenre}
                            </p>
                        )}
                        <p>
                            <Button
                                size="small"
                                center
                                color="red"
                                onClick={() =>
                                    this.setState({ showAllGenres: true })
                                }
                                type="button"
                            >
                                ALL GENRES
                            </Button>
                        </p>
                        <RadioGroup
                            name="level"
                            onChange={this._handleOnChange}
                            title="Level"
                            value={level.toString()}
                            values={{
                                '0': 'Easy',
                                '1': 'Medium',
                                '2': 'Hard',
                            }}
                        />
                        {showAllGenres && (
                            <Modal>
                                <Input
                                    className={css.genreFilter}
                                    type="text"
                                    value={genreFilter}
                                    name="genreFilter"
                                    onChange={this.handleGenreFilter}
                                />
                                <ul className={css.allGenres}>
                                    {genres
                                        .filter(this.filterGenres)
                                        .map((g) => (
                                            <li key={g.name}>
                                                <button
                                                    type="button"
                                                    className={cn({
                                                        [css.activeGenre]:
                                                            genre === g.id,
                                                    })}
                                                    data-genre={g.id}
                                                    onClick={this._setGenre}
                                                >
                                                    {g.name}
                                                </button>
                                            </li>
                                        ))}
                                </ul>
                            </Modal>
                        )}
                    </>
                )}
                {game.mode === GAMEMODE.PLAYLIST && (
                    <PlaylistPicker setPlaylist={this._setPlaylist} />
                )}
                {error && (
                    <div className={css.error}>
                        An error occured, please try again
                    </div>
                )}
                <Button
                    center={true}
                    className={css.play}
                    color="purple"
                    disabled={(!genre && !playlist.pid) || loading}
                    onClick={this._handleOnSubmit}
                    type="submit"
                >
                    Select
                </Button>
                {loading && <Loader />}
            </Form>
        )
    }
}

export default GameQuestions
